import {Inject, Injectable, InjectionToken, LOCALE_ID, Optional} from '@angular/core';
import {
    Observable,
    throwError,
    map,
    of,
    tap,
    switchMap,
    finalize,
    catchError,
} from 'rxjs';
import {manualLinkIds, ManualLinkKey} from './manual-link-keys.config';
import {
    CommonsError,
    FrontendServicesEndpoints, FrontendServicesQueryParams, IManualGlossaryResult,
    IManualMenuResult,
    IManualResult, IManualSearchContent, IManualSearchResult,
    SERVICES_URL,
} from '@active-agent/types';
import {HttpClient} from '@angular/common/http';
import {IManualPageContent} from '@angular-clan/core/manual/types';
import {CoreManualDialogService} from '@angular-clan/core/manual';
import {ERROR_HANDLER, ICommonsErrorHandler} from '@active-agent/error';
import {MatDialogRef} from '@angular/material/dialog';
import {CoreManualDialogComponent} from '@angular-clan/core/manual/manual-dialog.component';
import {LoadingDataService} from '@active-agent/global-loading-indicator';
import {ISecondarySidenavEntry} from '@angular-clan/core/secondary-sidenav';
import {LibsAppDataService} from '@active-agent/app-data';

@Injectable({
    providedIn: 'root',
})
class LibsManualService {
    constructor(
        private coreManualDialogService: CoreManualDialogService,
        private httpClient: HttpClient,
        private loadingDataService: LoadingDataService,
        @Optional() @Inject(ERROR_HANDLER) private errorHandler: ICommonsErrorHandler | null,
        @Optional() @Inject(SERVICES_URL) protected servicesUrl: string | null,
        @Inject(LOCALE_ID) public locale: string,
        @Optional() @Inject(MANUAL_APP_PATH) public manualAppPath: string,
        @Optional() @Inject(MANUAL_APP_PATH_REDIRECT_FOR_ID) public manualAppPathForRedirectById: string,
        private appData: LibsAppDataService,
    ) {}

    public search(query: string): Observable<Array<IManualSearchContent>> {
        return this.httpClient
            .get<IManualSearchResult>(
                `${this.servicesUrl}/${FrontendServicesEndpoints.ManualSearch}/${this.locale}`,
                {params: {[FrontendServicesQueryParams.SearchQuery]: query}},
            )
            .pipe(
                map(({data}: IManualSearchResult) => data),
                catchError((error: Error) => {
                    const customError: CommonsError = new CommonsError(
                        'failed to load search for manual',
                        {data: {locale: this.locale, error}},
                    );
                    void this.errorHandler?.handle(customError);

                    return throwError(() => customError);
                }),
            );
    }

    public getGlossaryEntries(): Observable<Record<string, string>> {
        return this.httpClient
            .get<IManualGlossaryResult>(`${this.servicesUrl}/${FrontendServicesEndpoints.ManualGlossary}/${this.locale}`)
            .pipe(
                map(({data}: IManualGlossaryResult) => data),
                catchError((error: Error) => {
                    const customError: CommonsError = new CommonsError(
                        'failed to load manual glossary',
                        {data: {locale: this.locale, error}},
                    );
                    void this.errorHandler?.handle(customError);

                    return throwError(() => customError);
                }),
            );
    }

    public getManualContentByKey(key: ManualLinkKey): Observable<IManualPageContent> {
        const manualLinkId: number | undefined = manualLinkIds[key];
        if (!this.servicesUrl || !manualLinkId) {
            const error: CommonsError = new CommonsError(
                'invalid manual key',
                {data: {key}},
            );
            void this.errorHandler?.handle(error);

            return throwError(() => error);
        }

        return this.getManualContent(manualLinkId);
    }

    public getManualContent(slug: string | number): Observable<IManualPageContent> {
        return of(null)
            .pipe(
                tap(() => this.loadingDataService.setData({isLoading: true})),
                switchMap(() => {
                    const params: Record<string, string> = {
                        [FrontendServicesQueryParams.ManualDomain]: `${this.appData.getCurrentNetwork().id}/${this.manualAppPath}`,
                    };

                    return this.httpClient
                        .get<IManualResult>(
                            `${this.servicesUrl}/${FrontendServicesEndpoints.ManualPosts}/${slug}`,
                            {params});
                }),
                map((result: IManualResult): IManualPageContent => result.data),
                finalize(() => this.loadingDataService.setData({isLoading: false})),
                catchError((error: Error) => {
                    const customError: CommonsError = new CommonsError(
                        'failed to load manual content',
                        {data: {slug, error}},
                    );
                    void this.errorHandler?.handle(customError);

                    return throwError(() => customError);
                }),
            );
    }

    public getMenuEntries(): Observable<Array<ISecondarySidenavEntry>> {
        return this.httpClient
            .get<IManualMenuResult>(`${this.servicesUrl}/${FrontendServicesEndpoints.ManualMenu}/${this.locale}`)
            .pipe(
                map(({data}: IManualMenuResult) => data),
                catchError((error: Error) => {
                    const customError: CommonsError = new CommonsError(
                        'failed to load manual menu',
                        {data: {locale: this.locale, error}},
                    );
                    void this.errorHandler?.handle(customError);

                    return throwError(() => customError);
                }),
            );
    }

    public openDialog(key: ManualLinkKey): Observable<MatDialogRef<CoreManualDialogComponent>> {
        const externalLink: string = this.getExternalLink(key);

        return this.getManualContentByKey(key)
            .pipe(
                map((page: IManualPageContent): MatDialogRef<CoreManualDialogComponent> => {
                    return this.coreManualDialogService.openDialog(page, externalLink);
                }),
            );

    }

    public getExternalLink(key: ManualLinkKey): string {
        const manualLinkId: number | undefined = manualLinkIds[key];

        return `${this.appData.getCurrentNetwork().id}/${this.manualAppPathForRedirectById}/${manualLinkId}`;
    }
}

const MANUAL_APP_PATH: InjectionToken<string>
    = new InjectionToken<string>('manual-service.manual-app-path');
const MANUAL_APP_PATH_REDIRECT_FOR_ID: InjectionToken<string>
    = new InjectionToken<string>('manual-service.manual-app-path-redirect-for-id');

export {LibsManualService, MANUAL_APP_PATH, MANUAL_APP_PATH_REDIRECT_FOR_ID};
