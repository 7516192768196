import {Observable, of} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

type GenericFilterProperties = IFilterProperties;

class GenericFilter extends Filter {
    protected _type: FilterTypes = FilterTypes.GENERIC;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = false;
    protected readonly _isAvailableForPreview: boolean = false;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: GenericFilterProperties = this.getDefaultOptions();

    public set type(value: FilterTypes) {
        this._type = value;
    }

    public canBeInventoried(): Observable<boolean> {
        return of(false);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    public isValid(): Observable<boolean> {
        return of(false);
    }
}

export {GenericFilter, GenericFilterProperties};
