import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class AdFarmIdValidator {
    public static readonly maxValue: number = 20_000_000;
    public static readonly minValue: number = 0;
    public static readonly pattern: RegExp = /^[1-9][0-9]*$/;

    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | undefined | null>): ValidationErrors | null {
        const adFarmId: number | undefined | null = control.value;

        if (adFarmId === undefined || adFarmId === null) {
            return {[AdFarmIdErrorCodes.Required]: this.getTranslationForErrorCode(AdFarmIdErrorCodes.Required)};
        }

        if (!AdFarmIdValidator.pattern.test(String(adFarmId))) {
            return {[AdFarmIdErrorCodes.Pattern]: this.getTranslationForErrorCode(AdFarmIdErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: AdFarmIdErrorCodes): string {
        switch (code) {
            case AdFarmIdErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case AdFarmIdErrorCodes.Pattern:
                return $localize`:@@AD_FARM_ID_VALIDATOR_PATTERN_ERROR_MESSAGE:AD_FARM_ID_VALIDATOR_PATTERN_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum AdFarmIdErrorCodes {
    Required = '2212f16c-d9d6-4270-bde1-c583318145d3',
    Pattern = '97342b63-fd52-4ae3-a188-70ee45391a65',
}

export {AdFarmIdValidator, AdFarmIdErrorCodes};
