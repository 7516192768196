import {
    ChangeDetectionStrategy, Component, input, Input, InputSignal, OnInit,
} from '@angular/core';
import {
    Advertiser,
    CommonsError,
    Campaign,
    Order,
    Banner,
    BannerPool,
    TrackingPage,
    TrackingSpot,
    Network,
} from '@active-agent/types';
import {ICommonsStaticInformationField} from '../static-information/static-information.component';
import {ContentUnit} from '@active-agent/content-unit';

@Component({
    selector: 'commons-default-static-information',
    templateUrl: 'default-static-information.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class CommonsDefaultStaticInformationComponent implements OnInit {
    public fields: Array<ICommonsStaticInformationField> = [];
    public addMargin: InputSignal<boolean> = input(true);
    @Input({required: true})
    public model!: Network | Advertiser | Order | Campaign | Banner | TrackingPage | TrackingSpot | BannerPool | ContentUnit;

    public ngOnInit(): void {
        if (this.model === undefined) {
            throw new CommonsError('model binding has to be provided');
        }

        this.initializeFields();
    }

    private initializeFields(): void {
        this.fields = [{
            description: `${String(this.model.id)}`,
            header: $localize`:@@ID:ID`,
        }];
    }
}

export {CommonsDefaultStaticInformationComponent};
