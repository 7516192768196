import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {CommonsBorderStyle} from '../list-item/list-item.component';
import {CommonsError} from '@active-agent/types';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'ad-select-list-item',
    templateUrl: './select-list-item.html',
    styleUrls: ['./select-list-item.scss'],
})
class AdSelectListItemComponent implements OnInit {
    @Input({required: true}) public title!: string;
    @Input() public titleTooltip: string = '';
    @Input() public subtitle?: string;
    @Input() public isActive: boolean | null = null;
    @Input() public isRequired: boolean = false;
    @Input() public placeholder: string = '';
    @Input() public borderStyle: CommonsBorderStyle = 'dotted';
    @Input() public hasPadding: boolean = true;
    @Input({required: true}) public options!: Array<ISelectOption<any>>;
    /**
     * Value of the select control.
     */
    @Input() public selectedOptionValue: SelectOptionValue<any> = null;
    /**
     * Whether the component is disabled.
     */
    @Input() public isDisabled: boolean = false;
    /**
     * A function to compare the option values with the selected values.
     * The first argument is a value from an option.
     * The second is a value from the selection.
     * A boolean should be returned.
     */
    @Input() public compareOptionsWithSelectedValues: (o1: SelectOptionValue<any>, o2: SelectOptionValue<any>) => boolean =
        defaultCompareOptionsWithSelectedValuesCallback;
    @Output() public onChange: EventEmitter<SelectOptionValue<any>> = new EventEmitter();

    public ngOnInit(): void {
        if (!this.options) {
            throw new CommonsError('options component binding has to be provided');
        }
    }

    public onSelectionChange(selectedOption: MatSelectChange): void {
        this.onChange.emit(selectedOption.value);
    }
}

function defaultCompareOptionsWithSelectedValuesCallback(o1: SelectOptionValue<any>, o2: SelectOptionValue<any>): boolean {
    return o1 === o2;
}

interface ISelectOption<T> {
    value: SelectOptionValue<T>;
    name: string;
}

type SelectOptionValue<T> = T;

export {AdSelectListItemComponent, SelectOptionValue, ISelectOption};
