import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
    CommonsError,
    Network,
    DilNetwork,
    TargetingAttribute,
    DmpAttribute,
    AttributeType,
    getAttributeTypeTranslation,
} from '@active-agent/types';
import {ICommonsStaticInformationField} from '../static-information.component';

@Component({
    selector: 'libs-targeting-attribute-static-information',
    templateUrl: 'targeting-attribute-static-information.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class LibsTargetingAttributeStaticInformationComponent implements OnInit {
    @Input({required: true}) public attribute!: TargetingAttribute;
    @Input({required: true}) public network!: Network;
    public fields: Array<ICommonsStaticInformationField> = [];

    public ngOnInit(): void {
        if (this.attribute === undefined || this.network === undefined) {
            throw new CommonsError('attribute, and network binding has to be provided');
        }

        this.initializeFields();
    }

    private initializeFields(): void {
        const dilNetwork: DilNetwork | undefined = this.network.dilNetworks.find((currentNetwork: DilNetwork): boolean => {
            const networkId: number = this.attribute.network
                ? this.attribute.network.id || 0
                : 0;

            return currentNetwork.ownerNetworkId === networkId;
        });
        this.fields = [
            {
                description: `${this.attribute.id || 0}`,
                header: $localize`:@@ID:ID`,
            },
            {
                description: this.attribute instanceof DmpAttribute
                    ? getAttributeTypeTranslation(AttributeType.Dmp)
                    : getAttributeTypeTranslation(AttributeType.Tagging),
                header: $localize`:@@TYPE:TYPE`,
            },
            {
                description: this.attribute.isFromNetwork(this.network)
                    ? $localize`:@@ATTRIBUTE_OWNER_OWN_ATTRIBUTE:ATTRIBUTE_OWNER_OWN_ATTRIBUTE`
                    : dilNetwork ? dilNetwork.ownerNetworkName || '' : '',
                header: $localize`:@@ATTRIBUTE_OWNER:ATTRIBUTE_OWNER`,
            },
        ];
    }
}

export {LibsTargetingAttributeStaticInformationComponent};
