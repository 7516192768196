import {NgModule} from '@angular/core';
import {NameValidator} from './name-validator';
import {DailyClickValidator} from './daily-click-validator';
import {DailyImpressionValidator} from './daily-impression-validator';
import {OptionalTotalBudgetValidator} from './optional-total-budget-validator';
import {RequiredTotalBudgetValidator} from './required-total-budget-validator';
import {RedirectCampaignBannerHeightValidator} from './redirect-campaign-banner-height-validator';
import {TotalClickValidator} from './total-click-validator';
import {TotalImpressionValidator} from './total-impression-validator';
import {CommonModule, CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {OptionalDailyBudgetValidator} from './optional-daily-budget-validator.service';
import {RequiredMaxCpmValidator} from './required-max-cpm-validator';
import {MaxCpcvValidator} from './max-cpcv-validator';
import {MaxCpxValidator} from './max-cpx-validator';
import {MinVtrValidator} from './min-vtr-validator';
import {RequiredMaxCpcValidator} from './required-max-cpc-validator';
import {RequiredDailyBudgetValidator} from './required-daily-budget-validator';
import {TimeValidator} from './time-validator';
import {FootfallRadiusValidator} from './footfall-radius-validator';
import {FootfallNameValidator} from './footfall-name-validator';
import {FootfallConversionRuntimeStartTimeValidator} from './footfall-conversion-runtime-start-time-validator';
import {FootfallConversionRuntimeEndTimeValidator} from './footfall-conversion-runtime-end-time-validator';
import {FootfallConversionRuntimeValidator} from './footfall-conversion-runtime-validator';
import {FootfallControlGroupRuntimeStartTimeValidator} from './footfall-control-group-runtime-start-time-validator';
import {FootfallControlGroupRuntimeEndTimeValidator} from './footfall-control-group-runtime-end-time-validator';
import {FootfallControlGroupRuntimeValidator} from './footfall-control-group-runtime-validator';
import {FootfallRuntimeValidator} from './footfall-runtime-validator';
import {FootfallCountryCodeValidator} from './footfall-country-code-validator';
import {FootfallConversionTimeValueValidator} from './footfall-conversion-time-value-validator';
import {FootfallConversionTimeUnitValidator} from './footfall-conversion-time-unit-validator';
import {FootfallConversionTimeValidator} from './footfall-conversion-time-validator';
import {OptionalMaxCpcValidator} from './optional-max-cpc-validator';
import {OptionalMaxCpmValidator} from './optional-max-cpm-validator';
import {AttributeExpireDaysValidator} from './attribute-expire-days-validator';
import {AttributeKeyValidator} from './attribute-key-validator';
import {AttributeMaxHistoryEntriesValidator} from './attribute-max-history-entries-validator';
import {AttributeMinSecondsBetweenTagsValidator} from './attribute-min-seconds-between-tags-validator';
import {AttributeNameValidator} from './attribute-name-validator';
import {OptionalLandingPageValidator} from './optional-landing-page-validator';
import {RequiredClickUrlValidator} from './required-click-url-validator';
import {OptionalClickUrlValidator} from './optional-click-url-validator';
import {MaxCombinedBidFactorValidator} from './max-combined-bid-factor-validator';
import {HeightValidator} from './height-validator';
import {WidthValidator} from './width-validator';
import {ClickTagDomainValidator} from './click-tag-domain-validator';
import {StartTimeValidator} from './start-time-validator';
import {EndTimeValidator} from './end-time-validator';
import {WrapperUrlValidator} from './wrapper-url-validator';
import {RedirectCampaignBannerWidthValidator} from './redirect-campaign-banner-width-validator';
import {ThirdPartyCostCpmValidator} from './third-party-cost-cpm-validator';
import {BidFactorValidator} from './bid-factor-vallidator';
import {HostingFeeValidator} from './hosting-fee-validator.service';
import {NetworkBudgetValidator} from './network-budget-validator';
import {DomainValidator} from './domain-validator';
import {AgencyIdValidator} from './agency-id-validator';
import {AgencyIdsValidator} from './agency-ids-validator';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        CommonModule,
    ],
    providers: [
        AgencyIdValidator,
        AgencyIdsValidator,
        AttributeExpireDaysValidator,
        AttributeKeyValidator,
        AttributeMaxHistoryEntriesValidator,
        AttributeMinSecondsBetweenTagsValidator,
        AttributeNameValidator,
        BidFactorValidator,
        ClickTagDomainValidator,
        CurrencyPipe,
        CurrencyPipe,
        DailyClickValidator,
        DailyImpressionValidator,
        DecimalPipe,
        DomainValidator,
        EndTimeValidator,
        FootfallControlGroupRuntimeEndTimeValidator,
        FootfallControlGroupRuntimeStartTimeValidator,
        FootfallControlGroupRuntimeValidator,
        FootfallConversionRuntimeEndTimeValidator,
        FootfallConversionRuntimeStartTimeValidator,
        FootfallConversionRuntimeValidator,
        FootfallConversionTimeUnitValidator,
        FootfallConversionTimeValidator,
        FootfallConversionTimeValueValidator,
        FootfallCountryCodeValidator,
        FootfallNameValidator,
        FootfallRadiusValidator,
        FootfallRuntimeValidator,
        HeightValidator,
        HostingFeeValidator,
        MaxCombinedBidFactorValidator,
        MaxCpcvValidator,
        MaxCpxValidator,
        MinVtrValidator,
        NameValidator,
        NetworkBudgetValidator,
        OptionalClickUrlValidator,
        OptionalDailyBudgetValidator,
        OptionalLandingPageValidator,
        OptionalMaxCpcValidator,
        OptionalMaxCpcValidator,
        OptionalMaxCpmValidator,
        OptionalMaxCpmValidator,
        OptionalTotalBudgetValidator,
        PercentPipe,
        RedirectCampaignBannerHeightValidator,
        RedirectCampaignBannerWidthValidator,
        RequiredClickUrlValidator,
        RequiredDailyBudgetValidator,
        RequiredMaxCpcValidator,
        RequiredMaxCpmValidator,
        RequiredTotalBudgetValidator,
        StartTimeValidator,
        ThirdPartyCostCpmValidator,
        TimeValidator,
        TimeValidator,
        TotalClickValidator,
        TotalImpressionValidator,
        WidthValidator,
        WrapperUrlValidator,
    ],
})
class CommonsValidatorsModule {}

export {CommonsValidatorsModule};
