import {differenceInSeconds} from 'date-fns';
import {UnauthorizedRequestError} from '../error';
import {CopyEvent} from './copy-event.types';

type OnCompletionCallback = () => void;
interface ICopyProcessQueueEntry {
    event: CopyEvent;
}

class CopyProcess <T extends ICopyProcessQueueEntry> {
    private remainingTime: number = 0;
    private startTime: Date = new Date();
    private endTime: Date | null = null;
    private readonly queue: Array<T> = [];
    private processedPromises: number = 0;
    private errorPromises: number = 0;
    private onCompletionCallback?: OnCompletionCallback;
    private readonly uuid: string = window.crypto.randomUUID();

    constructor(queue: Array<T>) {
        this.queue = queue;
        this.processedPromises = 0;
        this.errorPromises = 0;

        this.uuid = window.crypto.randomUUID();

        this.queue.forEach((entry: T) => {
            entry.event
                .getPromise()
                .catch((error: any): any => {
                    if (!(error instanceof UnauthorizedRequestError)) {
                        this.updateErrorPromises();
                    }
                })
                .then(() => {
                    this.processedPromises = this.processedPromises + 1;
                    this.remainingTime = this.calculateTimeRemaining();

                    if (this.getAmountProcessed() === this.getQueueSize()) {
                        this.endTime = new Date();
                        if (this.onCompletionCallback) {
                            this.onCompletionCallback();
                        }
                    }
                });
        });
    }

    public getQueue(): Array<T> {
        return this.queue;
    }

    public getQueueSize(): number {
        return this.queue.length;
    }

    public getAmountProcessed(): number {
        return this.processedPromises;
    }

    public isInProgress(): boolean {
        if (!this.queue.length) {
            return false;
        }

        return this.getAmountProcessed() < this.getQueueSize();
    }

    public getTimeRemaining(): number {
        return this.remainingTime;
    }

    public calculateTimeRemaining(): number {
        const timeDiff: number = differenceInSeconds(new Date(), this.startTime);
        if (!this.getAmountProcessed()) {
            return 0;
        }

        return Math.floor(
            (timeDiff / this.getAmountProcessed()) * (this.getQueueSize() - this.getAmountProcessed()),
        );
    }

    public onCompletion(callback: OnCompletionCallback): void {
        this.onCompletionCallback = callback;
    }

    public getAmountErrors(): number {
        return this.errorPromises;
    }

    public getDurationInSeconds(): number {
        return this.endTime ? differenceInSeconds(this.endTime, this.startTime) : 0;
    }

    public getUuid(): string {
        return this.uuid;
    }

    private updateErrorPromises(): void {
        this.errorPromises++;
    }
}

export {CopyProcess, ICopyProcessQueueEntry};
