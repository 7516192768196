import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validator,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    RtbCampaignOptimization,
    getCpxMetricsOptions,
    ICpxMetricOption,
    Optimization,
    CommonsError,
} from '@active-agent/types';
import {
    MinVtrValidator,
    MaxCpcvValidator,
    RequiredMaxCpcValidator,
    MaxCpxValidator,
    CpxMetricsValidator,
} from '@active-agent/validators';
import {TransformHelper} from '@active-agent/std';
import {LibsSpacerSize} from '../spacer/spacer.component';
import {CoreInfoBoxComponent, InfoBoxLevel} from '@angular-clan/core/info-box';
import {LibsSpacerModule} from '../spacer/spacer.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CoreNumberInputModule} from '@angular-clan/core';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';

@Component({
    selector: 'libs-campaign-optimization-settings',
    templateUrl: 'optimization-settings.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LibsCampaignOptimizationSettingsComponent),
            multi: true,
        },
    ],
    imports: [
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatSelectModule,
        MatCheckboxModule,
        CoreNumberInputModule,
        MatListModule,
        MatDividerModule,
        CoreInfoBoxComponent,
        LibsSpacerModule,
    ],
    standalone: true,
})
class LibsCampaignOptimizationSettingsComponent implements ControlValueAccessor, OnInit, AfterViewInit, Validator {
    @Input({required: true}) public formControlName!: string;
    @Input() public isReadOnly: boolean = false;
    @Input() public usedInTableInlineEdit: boolean = false;

    public form: UntypedFormGroup = this.formBuilder.group({
        optimization: [getDefaultOptimizationValue(), Validators.required],
        maxCpc: [{value: null, disabled: true}, [this.maxCpcValidator.validate]],
        maxCpx: [{value: null, disabled: true}, [this.maxCpxValidator.validate]],
        maxCpcv: [{value: null, disabled: true}, [this.maxCpcvValidator]],
        minVtr: [{value: null, disabled: true}, [this.minVtrValidator]],
        // create a separate value because we don't want them to have the same value if you swap between optimizations
        vtrOptimizationMaxCpcv: [{value: null, disabled: true}, [this.maxCpcvValidator]],
        cpxMetrics: new UntypedFormArray([]),
    });
    public spacerSize: LibsSpacerSize = LibsSpacerSize.Small;
    public readonly infoStatus: InfoBoxLevel = InfoBoxLevel.Info;
    public readonly errorStatus: InfoBoxLevel = InfoBoxLevel.Error;
    public readonly optimizationList: Array<IOptimizationList> = [
        {
            id: 'NONE',
            name: $localize`:@@OPTIMIZATION_TYPE_NONE:OPTIMIZATION_TYPE_NONE`,
        },
        {
            id: 'CPC',
            name: $localize`:@@OPTIMIZATION_TYPE_CPC:OPTIMIZATION_TYPE_CPC`,
        },
        {
            id: 'CPX',
            name: $localize`:@@OPTIMIZATION_TYPE_CPX:OPTIMIZATION_TYPE_CPX`,
        },
        {
            id: 'CPCV',
            name: $localize`:@@OPTIMIZATION_TYPE_CPCV:OPTIMIZATION_TYPE_CPCV`,
        },
        {
            id: 'VTR',
            name: $localize`:@@OPTIMIZATION_TYPE_VTR:OPTIMIZATION_TYPE_VTR`,
        },
    ];
    public readonly cpxMetricsOptions: Array<ICpxMetricOption> = getCpxMetricsOptions();

    private onControlChange: ((settings: RtbCampaignOptimization) => void) | undefined;
    private onControlTouched: (() => void) | undefined;
    private onValidationChange: (() => void) | undefined;

    private parentControl?: AbstractControl;

    constructor(
        @Optional() @Host() @SkipSelf()
        private controlContainer: ControlContainer,
        private formBuilder: UntypedFormBuilder,
        public maxCpcValidator: RequiredMaxCpcValidator,
        public maxCpxValidator: MaxCpxValidator,
        public maxCpcvValidator: MaxCpcvValidator,
        public minVtrValidator: MinVtrValidator,
    ) {
    }

    public ngOnInit(): void {
        if (!this.controlContainer || !this.controlContainer.control || !this.formControlName) {
            throw new CommonsError('Missing parent FormGroup directive or formControlName from host element of the component');
        }

        if (this.controlContainer && this.formControlName && this.controlContainer.control) {
            const control: AbstractControl | null = this.controlContainer.control.get(this.formControlName);
            if (control) {
                this.parentControl = control;
                this.parentControl.setValidators(this.validate.bind(this));
            }
        }
    }

    public ngAfterViewInit(): void {
        if (this.isReadOnly) {
            this.form.controls.optimization.disable();
            this.form.controls.cpxMetrics.disable();
        } else {
            this.form.controls.optimization.enable();
            this.form.controls.cpxMetrics.enable();
        }

        this.form.controls.maxCpc.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.maxCpx.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.maxCpcv.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.minVtr.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.vtrOptimizationMaxCpcv.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.cpxMetrics.valueChanges.subscribe(this.onChange.bind(this));
        this.form.controls.optimization.valueChanges.subscribe((optimization: Optimization) => {
            this.onOptimizationChange(optimization);
        });
    }

    public registerOnChange(fn: (url: RtbCampaignOptimization) => void): void {
        this.onControlChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        const formControls: Array<AbstractControl> = [
            this.form.controls.maxCpc,
            this.form.controls.maxCpx,
            this.form.controls.maxCpcv,
            this.form.controls.minVtr,
            this.form.controls.vtrOptimizationMaxCpcv,
            this.form.controls.optimization,
            this.form.controls.cpxMetrics,
        ];
        if (isDisabled) {
            formControls.forEach((formControl: AbstractControl) => formControl.disable());
        } else {
            formControls.forEach((formControl: AbstractControl) => formControl.enable());
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.form.controls.maxCpc.invalid
            || this.form.controls.maxCpx.invalid
            || this.form.controls.maxCpcv.invalid
            || this.form.controls.minVtr.invalid
            || this.form.controls.vtrOptimizationMaxCpcv.invalid
            || this.form.controls.optimization.invalid
            || this.form.controls.cpxMetrics.invalid
            ? {invalidSettings: {valid: false, message: 'optimization settings is invalid'}}
            : null;
    }

    public writeValue(obj: RtbCampaignOptimization): void {
        if (!this.form) {
            return;
        }
        this.onOptimizationChange(obj.optimization);
        this.form.controls.optimization.setValue(obj.optimization);

        switch (obj.optimization) {
            case 'CPC':
                this.form.controls.maxCpc.setValue(obj.maxCpc ? TransformHelper.centToEuro(obj.maxCpc) : null);
                break;
            case 'CPX':
                this.form.controls.maxCpx.setValue(obj.maxCpx);
                break;
            case 'CPCV':
                this.form.controls.maxCpcv.setValue(obj.maxCpcv ? TransformHelper.centToEuro(obj.maxCpcv) : null);
                break;
            case 'VTR':
                this.form.controls.vtrOptimizationMaxCpcv.setValue(obj.maxCpcv ? TransformHelper.centToEuro(obj.maxCpcv) : null);
                this.form.controls.minVtr.setValue(obj.minVtr);
                break;
            default:
                break;
        }
        const cpxMetrics: AbstractControl = this.form.controls.cpxMetrics;
        if (cpxMetrics instanceof UntypedFormArray) {
            if (obj.optimization === 'CPX') {
                for (const option of this.cpxMetricsOptions) {
                    option.control.setValue(obj.cpxMetrics.includes(option.id));
                }
            }
            this.cpxMetricsOptions.forEach((option: ICpxMetricOption) => {
                cpxMetrics.push(option.control);
            });
        } else {
            throw new CommonsError('seems to be the wrong instance');
        }
    }

    public isNoneOptimizationSelected(): boolean {
        return this.form.controls.optimization.value === 'NONE';
    }

    public isCpcOptimizationSelected(): boolean {
        return this.form.controls.optimization.value === 'CPC';
    }

    public isCpxOptimizationSelected(): boolean {
        return this.form.controls.optimization.value === 'CPX';
    }

    public isCpcvOptimizationSelected(): boolean {
        return this.form.controls.optimization.value === 'CPCV';
    }

    public isVtrOptimizationSelected(): boolean {
        return this.form.controls.optimization.value === 'VTR';
    }

    public areCpxMetricsSelected(): boolean {
        return this.cpxMetricsOptions.some((option: ICpxMetricOption) => option.control.value);
    }

    private onOptimizationChange(optimization: Optimization): void {
        this.form.controls.maxCpc.disable();
        this.form.controls.maxCpc.clearValidators();

        this.form.controls.maxCpx.disable();
        this.form.controls.maxCpx.clearValidators();

        this.form.controls.cpxMetrics.disable();
        this.form.controls.cpxMetrics.clearValidators();

        this.form.controls.maxCpcv.disable();
        this.form.controls.maxCpcv.clearValidators();

        this.form.controls.vtrOptimizationMaxCpcv.disable();
        this.form.controls.vtrOptimizationMaxCpcv.clearValidators();

        this.form.controls.minVtr.disable();
        this.form.controls.minVtr.clearValidators();

        switch (optimization) {
            case 'CPC':
                this.form.controls.maxCpc.enable();
                this.form.controls.maxCpc.setValidators(this.maxCpcValidator.validate);

                return;
            case 'CPX':
                this.form.controls.maxCpx.enable();
                this.form.controls.maxCpx.setValidators(this.maxCpxValidator.validate);

                this.form.controls.cpxMetrics.enable();
                this.form.controls.cpxMetrics.setValidators(CpxMetricsValidator());

                return;
            case 'CPCV':
                this.form.controls.maxCpcv.enable();
                this.form.controls.maxCpcv.setValidators(this.maxCpcvValidator.validate);

                return;
            case 'VTR':
                this.form.controls.vtrOptimizationMaxCpcv.enable();
                this.form.controls.vtrOptimizationMaxCpcv.setValidators(this.maxCpcvValidator.validate);

                this.form.controls.minVtr.enable();
                this.form.controls.minVtr.setValidators(this.minVtrValidator.validate);

                return;
            default:
            case 'NONE':

                return;
        }

        this.onChange();
    }

    private onChange(): void {
        if (!this.form) {
            return;
        }
        const settings: RtbCampaignOptimization = new RtbCampaignOptimization();
        settings.optimization = this.form.controls.optimization.value;
        switch (settings.optimization) {
            case 'CPC':
                settings.maxCpc = this.form.controls.maxCpc.value ? TransformHelper.euroToCent(this.form.controls.maxCpc.value) : null;
                break;
            case 'CPX':
                settings.maxCpx = this.form.controls.maxCpx.value;
                settings.cpxMetrics = this.cpxMetricsOptions
                    .filter((option: ICpxMetricOption) => {
                        return option.control.value;
                    })
                    .map((option: ICpxMetricOption) => option.id);
                break;
            case 'CPCV':
                settings.maxCpcv = this.form.controls.maxCpcv.value ? TransformHelper.euroToCent(this.form.controls.maxCpcv.value) : null;
                break;
            case 'VTR':
                settings.maxCpcv = this.form.controls.vtrOptimizationMaxCpcv.value
                    ? TransformHelper.euroToCent(this.form.controls.vtrOptimizationMaxCpcv.value)
                    : null;
                settings.minVtr = this.form.controls.minVtr.value;
                break;
            default:
                break;
        }

        if (this.onControlChange && this.onControlTouched) {
            this.onControlChange(settings);
            this.onControlTouched();
        }
        /**
         * Still unsure why this is needed. Without it the disabled status is not being propagated correctly
         * on selection change
         */
        setTimeout(() => {
            if (this.parentControl) {
                this.parentControl.updateValueAndValidity();
            }
        });
    }
}

interface IOptimizationList {
    id: Optimization;
    name: string;
}

function getDefaultOptimizationValue(): Optimization {
    return 'NONE';
}

export {LibsCampaignOptimizationSettingsComponent, getDefaultOptimizationValue};
