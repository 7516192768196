/* eslint-disable @stylistic/max-len */
/**
 * @link https://teamwork.vimico.com/confluence/display/APM/Pre-bid+Targeting+-+Segments+for+Platforms+API+Guide
 * Desktop/Mobile Web Segments
 */
enum IasBrandSafetyProtectionSegmentCode {
    EHAD = 'EHAD', // Avoid HIGH risk adult content pages as rated by lAS
    EHADV = 'EHADV', // Avoid HIGH risk adult content pages for video as rated by lAS
    EHAL = 'EHAL', // Avoid HIGH risk alcohol content pages as rated by lAS
    EHALV = 'EHALV', // Avoid HIGH risk alcohol content pages for video as rated by IAS
    EHDG = 'EHDG', // Avoid HIGH risk drug content pages as rated by IAS
    EHDGV = 'EHDGV', // Avoid HIGH risk drug content pages for video as rated by IAS
    EHDL = 'EHDL', // Avoid HIGH risk illegal download content pages as rated by IAS
    EHDLV = 'EHDLV', // Avoid HIGH risk illegal download content pages for video as rated by IAS
    EHHT = 'EHHT', // Avoid HIGH risk hate speech content pages as rated by IAS
    EHHTV = 'EHHTV', // Avoid HIGH risk hate speech content pages for video as rated by IAS
    EHMAD = 'EHMAD', // Avoid HIGH and MODERATE risk adult content pages as rated by lAS
    EHMADV = 'EHMADV', // Avoid HIGH and MODERATE risk adult content pages for video as rated by IAS
    EHMAL = 'EHMAL', // Avoid HIGH and MODERATE risk alcohol content pages as rated by IAS
    EHMALV = 'EHMALV', // Avoid HIGH and MODERATE risk alcohol content pages for video as rated by IAS
    EHMDG = 'EHMDG', // Avoid HIGH and MODERATE risk drug content pages as rated by lAS
    EHMDGV = 'EHMDGV', // Avoid HIGH and MODERATE risk drug content pages for video as rated by lAS
    EHMDL = 'EHMDL', // Avoid HIGH and MODERATE risk illegal download content pages as rated by lAS
    EHMDLV = 'EHMDLV', // Avoid HIGH and MODERATE risk illegal download content pages for video as rated by lAS
    EHMHT = 'EHMHT', // Avoid HIGH and MODERATE risk hate speech content pages as rated by IAS
    EHMHTV = 'EHMHTV', // Avoid HIGH and MODERATE risk hate speech content pages for video as rated by IAS
    EHMOF = 'EHMOF', // Avoid HIGH and MODERATE risk offensive language content pages as rated by lAS
    EHMOFV = 'EHMOFV', // Avoid HIGH and MODERATE risk offensive language content pages for video as rated by lAS
    EHMVIO = 'EHMVIO', // Avoid HIGH and MODERATE risk violence pages as rated by IAS
    EHMVIOV = 'EHMVIOV', // Avoid HIGH and MODERATE risk violence pages for video as rated by IAS
    EHOF = 'EHOF', // Avoid HIGH risk offensive language content pages as rated by lAS
    EHOFV = 'EHOFV', // Avoid HIGH risk offensive language content pages for video as rated by IAS
    EHVIO = 'EHVIO', // Avoid HIGH risk violence pages as rated by lAS
    EHVIOV = 'EHVIOV', // Avoid HIGH risk violence pages for video as rated by IAS
    EUNR = 'EUNR', // Avoid pages which IAS CANNOT RATE (for example, "blind" URLs, ad server URLS, ad network URLs, doubleclick.net, yieldmanager.com, IP addresses, etc.)
    EUNRV = 'EUNRV', // Avoid pages which IAS CANNOT RATE (for example, "blind" URLs, ad server URLS, ad network URLs, doubleclick.net, yieldmanager.com, IP addresses, etc.) for video
}

/**
 * Mobile App Segments
 * IAS supports brand safety protection segments for mobile app display. The following targeting definitions are the standard for mobile app display and video brand safety protection categories and are only applicable to the mobile app inventory supply. IAS rates all apps using these standard segments:
 */
enum IasBrandSafetyProtectionInAppSegmentCode {
    EHADA = 'EHADA', // Avoid HIGH risk adult content apps as rated by IAS	Display	Mobile App
    EHADAV = 'EHADAV', // Avoid HIGH risk adult content apps for video as rated by IAS	Video	Mobile App
    EHALA = 'EHALA', // Avoid HIGH risk alcohol content as rated by IAS	Display	Mobile App
    EHALAV = 'EHALAV', // Avoid HIGH risk alcohol content apps for video as rated by IAS	Video	Mobile App
    EHDGA = 'EHDGA', // Avoid HIGH risk drug content apps as rated by IAS	Display	Mobile App
    EHDGAV = 'EHDGAV', // Avoid HIGH risk drug content apps for video apps as rated by IAS	Video	Mobile App
    EHDLA = 'EHDLA', // Avoid HIGH risk illegal download content apps as rated by IAS	Display	Mobile App
    EHDLAV = 'EHDLAV', // Avoid HIGH risk illegal download content apps for video as rated by IAS	Video	Mobile App
    EHGA = 'EHGA', // Avoid HIGH risk gambling apps as rated by IAS	Display	Mobile App
    EHGAV = 'EHGAV', // Avoid HIGH risk gambling apps for video as rated by IAS	Video	Mobile App
    EHHTA = 'EHHTA', // Avoid HIGH risk hate speech content apps as rated by IAS	Display	Mobile App
    EHHTAV = 'EHHTAV', // Avoid HIGH risk hate speech content apps for video as rated by IAS	Video	Mobile App
    EHMADA = 'EHMADA', // Avoid HIGH and MODERATE risk adult content apps as rated by IAS	Display	Mobile App
    EHMADAV = 'EHMADAV', // Avoid HIGH and MODERATE risk adult content apps for video as rated by IAS	Video	Mobile App
    EHMALA = 'EHMALA', // Avoid HIGH and MODERATE risk alcohol content apps as rated by IAS	Display	Mobile App
    EHMALAV = 'EHMALAV', // Avoid HIGH and MODERATE risk alcohol content apps for video as rated by IAS	Video	Mobile App
    EHMDGA = 'EHMDGA', // Avoid HIGH and MODERATE risk drug content apps as rated by IAS	Display	Mobile App
    EHMDGAV = 'EHMDGAV', // Avoid HIGH and MODERATE risk drug content apps for video as rated by IAS	Video	Mobile App
    EHMDLA = 'EHMDLA', // Avoid HIGH and MODERATE risk illegal download content apps as rated by IAS	Display	Mobile App
    EHMDLAV = 'EHMDLAV', // Avoid HIGH and MODERATE risk illegal download content apps for video as rated by IAS	Video	Mobile App
    EHMGA = 'EHMGA', // Avoid HIGH and MODERATE risk gambling content apps as rated by IAS	Display	Mobile App
    EHMGAV = 'EHMGAV', // Avoid HIGH and MODERATE risk gambling content apps for video as rated by IAS	Video	Mobile App
    EHMHTA = 'EHMHTA', // Avoid HIGH and MODERATE hate speech content apps as rated by IAS	Display	Mobile App
    EHMHTAV = 'EHMHTAV', // Avoid HIGH and MODERATE hate speech content apps for video as rated by IAS	Video	Mobile App
    EHMOFA = 'EHMOFA', // Avoid HIGH and MODERATE risk offensive language content apps as rated by IAS	Display	Mobile App
    EHMOFAV = 'EHMOFAV', // Avoid HIGH and MODERATE risk offensive language content apps for video as rated by IAS	Video	Mobile App
    EHMVIOA = 'EHMVIOA', // Avoid HIGH and MODERATE risk violence content apps as rated by IAS	Display	Mobile App
    EHMVIOAV = 'EHMVIOAV', // Avoid HIGH and MODERATE risk violence content apps for video as rated by IAS	Video	Mobile App
    EHOFA = 'EHOFA', // Avoid HIGH risk offensive language content apps as rated by IAS	Display	Mobile App
    EHOFAV = 'EHOFAV', // Avoid HIGH risk offensive language content apps for video as rated by IAS	Video	Mobile App
    EHVIOA = 'EHVIOA', // Avoid HIGH risk violence content apps as rated by IAS	Display	Mobile App
    EHVIOAV = 'EHVIOAV', // Avoid HIGH risk violence content for video apps as rated by IAS	Video	Mobile App
    EUNRA = 'EUNRA', // Avoid valid apps, which IAS has not rated or cannot rate (for example, new apps, private/blind apps, apps without or limited descriptions, etc.)	Display	Mobile App
    EUNRAV = 'EUNRAV', // Avoid valid apps, which IAS has not rated or cannot rate (for example, new apps, private/blind apps, apps without or limited descriptions, etc.) for video
}

/**
 * Viewability - Time In-View
 */
enum IasViewabilityTimeInViewSegmentCode {
    TIVADD = 'TIVADD', // Target the top 30% of pages with longest time-in-view duration for display on desktop
    TIVCDD = 'TIVCDD', // Target the top 15% of pages with longest time-in-view duration for display on desktop
}

/**
 * Desktop/Mobile Web Segments/All
 * MRC
 */
enum IasViewabilityMrcPerformanceMatchingSegmentCode {
    PM40D = 'PM40D', // Target 40% viewability or higher for display campaigns	Display	All
    PM50D = 'PM50D', // Target 50% viewability or higher for display campaigns	Display	All
    PM60D = 'PM60D', // Target 60% viewability or higher for display campaigns	Display	All
    PM70D = 'PM70D', // Target 70% viewability or higher for display campaigns	Display	All
    PM40DD = 'PM40DD', // Target 40% viewability or higher for display campaigns	Display	Desktop
    PM40DM = 'PM40DM', // Target 40% viewability or higher for display campaigns on mobile web	Display	Mobile Web
    PM40V = 'PM40V', // Target 40% viewability or higher for video campaigns	Video	All
    PM40VD = 'PM40VD', // Target 40% viewability or higher for desktop video campaigns	Video	Desktop
    PM40VM = 'PM40VM', // Target 40% viewability or higher for mobile web video campaigns	Video	Mobile Web
    PM50DD = 'PM50DD', // Target 50% viewability or higher for display campaigns	Display	Desktop
    PM50DM = 'PM50DM', // Target 50% viewability or higher for display campaigns on mobile web	Display	Mobile Web
    PM50V = 'PM50V', // Target 50% viewability or higher for video campaigns	Video	All
    PM50VD = 'PM50VD', // Target 50% viewability or higher for desktop video campaigns	Video	Desktop
    PM50VM = 'PM50VM', // Target 50% viewability or higher for mobile web video campaigns	Video	Mobile Web
    PM60DD = 'PM60DD', // Target 60% viewability or higher for display campaigns	Display	Desktop
    PM60DM = 'PM60DM', // Target 60% viewability or higher for display campaigns mobile web	Display	Mobile Web
    PM60V = 'PM60V', // Target 60% viewability or higher for video campaigns	Video	All
    PM60VD = 'PM60VD', // Target 60% viewability or higher for desktop video campaigns	Video	Desktop
    PM60VM = 'PM60VM', // Target 60% viewability or higher for mobile web video campaigns	Video	Mobile Web
    PM70DD = 'PM70DD', // Target 70% viewability or higher for display campaigns	Display	Desktop
    PM70DM = 'PM70DM', // Target 70% viewability or higher for display campaigns mobile web	Display	Mobile Web
    PM70V = 'PM70V', // Target 70% viewability or higher for video campaigns	Video	All
    PM70VD = 'PM70VD', // Target 70% viewability or higher for desktop video campaigns	Video	Desktop
    PM70VM = 'PM70VM',	// Target 70% viewability or higher for mobile web video campaigns	Video	Mobile Web
    PMLD = 'PMLD', // Target up to 40% viewability for display campaigns	Display	All
    PMLDD = 'PMLDD', // Target up to 40% viewability for display campaigns	Display	Desktop
    PMLDM = 'PMLDM', // Target up to 40% viewability for display campaigns on mobile web	Display	Mobile Web
    PMLV = 'PMLV', // Target up to 40% viewability for video campaigns	Video	All
    PMLVD = 'PMLVD', // Target up to 40% viewability for desktop video campaigns	Video	Desktop
    PMLVM = 'PMLVM', // Target up to 40% viewability for mobile web video campaigns	Video	Mobile Web
}

/**
 * Viewability - GroupM Performance Matching by Ad Size
 */
enum IasViewabilityGroupMPerformanceMatchingSegmentCode {
    GM_PM40DD = 'GM_PM40DD',
    GM_PM50DD = 'GM_PM50DD',
    GM_PM60DD = 'GM_PM60DD',
    GM_PM70DD = 'GM_PM70DD',
    GM_PM40DM = 'GM_PM40DM',
    GM_PM50DM = 'GM_PM50DM',
    GM_PM60DM = 'GM_PM60DM',
    GM_PM70DM = 'GM_PM70DM',
}

/**
 * Viewability - Publicis Performance Matching by Ad Size
 */
enum IasViewabilityPublicisPerformanceMatchingByAdSizeSegmentCode {
    PMX_PM40DD = 'PMX_PM40DD',
    PMX_PM50DD = 'PMX_PM50DD',
    PMX_PM60DD = 'PMX_PM60DD',
    PMX_PM70DD = 'PMX_PM70DD',
    PMX_PM40DM = 'PMX_PM40DM',
    PMX_PM50DM = 'PMX_PM50DM',
    PMX_PM60DM = 'PMX_PM60DM',
    PMX_PM70DM = 'PMX_PM70DM',
}

/**
 * Viewability - MRC Performance Matching (in-App)
 */
enum IasViewabilityMrcPerformanceMatchingInAppSegmentCode {
    PM40DA = 'PM40DA',
    PM50DA = 'PM50DA',
    PM60DA = 'PM60DA',
    PM70DA = 'PM70DA',
}

/**
 * Contextual Targeting
 */
enum IasContextualTargetingSegmentCode {
    IAB1 = 'IAB1',
    IAB2 = 'IAB2',
    IAB3 = 'IAB3',
    IAB4 = 'IAB4',
    IAB5 = 'IAB5',
    IAB6 = 'IAB6',
    IAB7 = 'IAB7',
    IAB8 = 'IAB8',
    IAB9 = 'IAB9',
    IAB10 = 'IAB10',
    IAB11 = 'IAB11',
    IAB12 = 'IAB12',
    IAB13 = 'IAB13',
    IAB14 = 'IAB14',
    IAB15 = 'IAB15',
    IAB16 = 'IAB16',
    IAB17 = 'IAB17',
    IAB18 = 'IAB18',
    IAB19 = 'IAB19',
    IAB20 = 'IAB20',
    IAB21 = 'IAB21',
    IAB22 = 'IAB22',
    IAB23 = 'IAB23',
}

// eslint-disable-next-line @typescript-eslint/typedef
const IasSegmentCode = {
    ...IasBrandSafetyProtectionSegmentCode,
    ...IasBrandSafetyProtectionInAppSegmentCode,
    ...IasViewabilityTimeInViewSegmentCode,
    ...IasViewabilityMrcPerformanceMatchingSegmentCode,
    ...IasViewabilityGroupMPerformanceMatchingSegmentCode,
    ...IasViewabilityPublicisPerformanceMatchingByAdSizeSegmentCode,
    ...IasViewabilityMrcPerformanceMatchingInAppSegmentCode,
    ...IasContextualTargetingSegmentCode,
};
// eslint-disable-next-line no-redeclare
type IasSegmentCode = IasBrandSafetyProtectionSegmentCode
| IasBrandSafetyProtectionInAppSegmentCode
| IasViewabilityTimeInViewSegmentCode
| IasViewabilityMrcPerformanceMatchingSegmentCode
| IasViewabilityGroupMPerformanceMatchingSegmentCode
| IasViewabilityPublicisPerformanceMatchingByAdSizeSegmentCode
| IasViewabilityMrcPerformanceMatchingInAppSegmentCode
| IasContextualTargetingSegmentCode;

export {IasSegmentCode, IasViewabilityTimeInViewSegmentCode};
