import {
    AfterViewInit,
    Component,
    ElementRef,
    input,
    InputSignal,
    Renderer2,
    Signal,
    viewChild,
} from '@angular/core';

@Component({
    selector: 'libs-dynamic-progress-bar',
    templateUrl: 'dynamic-progress-bar.html',
    styleUrls: ['./dynamic-progress-bar.scss'],
    standalone: true,
})
class LibsDynamicProgressBarComponent implements AfterViewInit {
    public progressPercentage: InputSignal<number> = input.required();
    public disableAnimation: InputSignal<boolean> = input(false);
    public progressText: Signal<ElementRef> = viewChild.required('progressText');

    constructor(private renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        if (!this.hasProgressText()) {
            this.renderer.setAttribute(this.progressText().nativeElement, 'hidden', 'true');
        }
    }

    public hasProgressText(): boolean {
        const nativeElement: HTMLDivElement = this.progressText().nativeElement as HTMLDivElement;

        return nativeElement && !!nativeElement.textContent && nativeElement.textContent.length > 0;
    }
}

export {LibsDynamicProgressBarComponent};
