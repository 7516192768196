function getDomainTargetingDomainPattern(): RegExp {
    return /^(?:\w+:\/\/)?(?:www\.)?((?:[^.]+\.)+[^.]+|\d+)$/;
}

function getTrackingSpotCodePattern(): RegExp {
    return /<(img|script|iframe)(\s[^<>]*)?>/g;
}

function getAdSpotIdValuePattern(): RegExp {
    return /^[\w-]+$/i;
}

/**
 * Returns pattern for url without protocol.
 *
 * @returns {RegExp}
 */
function getBannerUrlPattern(): RegExp {
    return /^(([\w\-]+\.)*([\w\-]{2,}\.)+([a-z]{2,})|([\d]{1,3}\.){3}[\d]{1,3})(:[0-9]{1,})?([\/#?]([^\s])*)?$/i;
}

/**
 * @description
 * Returns a regex to validate a time (string)
 *
 * @example
 * 05:45
 * 5:45
 * 10:00
 *
 * @returns {RegExp} returns a regex
 */
function getTimePattern(): RegExp  {
    return /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/;
}

/**
 * regex for CR+LF to LF
 * @returns {RegExp}
 */
function getNewLinePattern(): RegExp {
    return /\r?\n|\r/g;
}

/**
 * Returns a default pattern used for validating names.
 */
function getDefaultNamePattern(): RegExp  {
    return /^((?![<>]).)*$/i;
}

function getBase64FilePattern(): RegExp  {
    return /data:(?:[a-zA-Z]*\/([a-zA-Z+-.0-9]*))?;base64,/;
}

function getHtml5ParamKeyPattern(): RegExp  {
    return /^[_a-zA-Z]\w+$/i;
}

function getHtml5ParamValuePattern(): RegExp {
    return /^((?![<>]).)*$/i;
}

/**
 * Returns pattern for url with protocol like in the Adition Frontend
 * Is used for:
 *
 * landingPage
 * clickUrl
 * wrapperUrl
 * tracking url
 * switch in url
 * switch in xxl
 * switch in xxl animated
 * native banner files
 *
 * https://teamwork.vimico.com/jira/browse/AAS-524
 *
 *
 * @returns {RegExp}
 */
function getUrlAditionPattern(): RegExp {
    return /^(https?:\/\/)?(([\w\-]+\.)*([\w\-]{1,}\.)+([a-z]{2,})|([\d]{1,3}\.){3}[\d]{1,3})(:[0-9]{1,})?([\/#?]([^\s])*)?$/;
}

function getFilterNamePattern(): RegExp  {
    return /^[a-zA-ZÄÖÜäöü][\wÄÖÜäöüß-]*$/i;
}

/**
 * Returns Pattern for Domain Validation like in the Adition Frontend.
 *
 * @returns {RegExp}
 */
function getAdvertiserDomainPattern(): RegExp {
    return /^([a-z\d](-*[a-z\d])*)(\.([a-z\d](-*[a-z\d])*))*$/i;
}

function getTargetingPeculiarityNamePattern(): RegExp  {
    return /^\w+$/i;
}

function getTargetingPeculiaritySubKeyPattern(): RegExp  {
    return /^\w+$/i;
}

function getAttributeNamePattern(): RegExp  {
    return /^[a-zA-Z]\w+$/i;
}

function getAttributeKeyPattern(): RegExp  {
    return /^[a-zA-Z]\w+$/i;
}

/**
 * Returns pattern for the protocol of a url.
 * @returns {RegExp}
 */
function getUrlProtocolPattern(): RegExp {
    return /^([a-z]+:\/\/)/i;
}

function getCustomSiteContextualPrefixPattern(): RegExp {
    return /^([a-zA-Z]+)\d*$/;
}

function getOnlyNumbersPattern(): RegExp {
    return /^\d+$/;
}

export {
    getDefaultNamePattern,
    getBase64FilePattern,
    getHtml5ParamKeyPattern,
    getUrlAditionPattern,
    getHtml5ParamValuePattern,
    getFilterNamePattern,
    getAdvertiserDomainPattern,
    getNewLinePattern,
    getTimePattern,
    getBannerUrlPattern,
    getTargetingPeculiarityNamePattern,
    getTargetingPeculiaritySubKeyPattern,
    getAttributeNamePattern,
    getAttributeKeyPattern,
    getUrlProtocolPattern,
    getCustomSiteContextualPrefixPattern,
    getOnlyNumbersPattern,
    getDomainTargetingDomainPattern,
    getTrackingSpotCodePattern,
    getAdSpotIdValuePattern,
};
