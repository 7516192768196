import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CommonsRequestBuilder} from './request-builder';
import {CommonsError} from '@active-agent/types';
import {map} from 'rxjs/operators';

class CommonsDeleteRequestBuilder extends CommonsRequestBuilder {
    private parentId?: number | string;

    constructor(
        private http: HttpClient,
        resourcePath: string,
        baseUrl: string,
    ) {
        super(resourcePath, baseUrl);
    }

    public setParentId(id: number | string): this {
        this.parentId = id;

        return this;
    }

    /**
     * Creates a valid api remove request
     */
    public build(): Observable<void> {
        return this.remove();
    }

    /**
     * Returns an Observable of the object deletion with the given id on the given resource.
     */
    private remove(): Observable<void> {
        const queryIds: Array<number | string> | null = this.getQueryIds();

        if (!queryIds || !queryIds.length) {
            return throwError(() => new CommonsError('queryIds must be given', {
                data: {
                    resourcePath: this.getResourcePath(),
                    subResourcePath: this.getSubResourcePath(),
                    parentId: this.parentId,
                },
            }));
        }

        let url: string = `${this.getApiBaseUrl()}/${queryIds.join(',')}`;

        if (this.getSubResourcePath()) {
            if (!this.parentId) {
                return throwError(() => new CommonsError(
                    'param "parentId" must be given',
                    {
                        data: {
                            resourcePath: this.getResourcePath(),
                            subResourcePath: this.getSubResourcePath(),
                        },
                    },
                ));
            }
            url = `${this.getApiBaseUrl()}/${this.parentId}/${this.getSubResourcePath()}/${queryIds.join(',')}`;
        }

        return this.http
            .delete(url, {headers: this.getHeadersForRequest()})
            .pipe(
                map((): void => {
                    // we don't want to return anything
                }),
            );
    }
}

export {CommonsDeleteRequestBuilder};
