import {Observable, of} from 'rxjs';
import {cloneDeep} from 'lodash-es';
import {parseVideoPlayerFilterImport, VideoPlayerFilterImportParserResult} from './video-player-filter-import';
import {IVideoPlayerFilterOption, videoPlayerFilterOptions} from './video-player-filter-options';
import {FilterTypes} from '../types/filter.types';
import {Filter, IFilterProperties} from '../types/filter';

interface IVideoPlayerFilterProperties extends IFilterProperties {
    profile: string;
}

class VideoPlayerFilter extends Filter<FilterTypes.VIDEO_PLAYER> {
    protected readonly _type: FilterTypes.VIDEO_PLAYER = FilterTypes.VIDEO_PLAYER;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IVideoPlayerFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isVideoPlayerProfileValid(this.profile));
    }

    protected getDefaultOptions(): IVideoPlayerFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isVideoPlayerProfileValid(profile: string = ''): boolean {
    try {
        const parsedData: VideoPlayerFilterImportParserResult = parseVideoPlayerFilterImport(profile);
        for (const option of cloneDeep(videoPlayerFilterOptions)) {
            option.active = Object.entries(parsedData).some(([k, v]: [string, boolean]) => k === option.id && v);
        }

        return videoPlayerFilterOptions.some((option: IVideoPlayerFilterOption) => {
            return option.active;
        });
    } catch (_error: unknown) {
        return false;
    }
}

export {VideoPlayerFilter, IVideoPlayerFilterProperties, isVideoPlayerProfileValid};
