import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
class ClickTagDomainValidator {
    public static readonly maxLength: number = 255;
    public static readonly pattern: RegExp =
        /^([a-z]+:\/\/)?(([\w\-]+\.)*([\w\-]{2,}\.)+([a-z]{2,})|([\d]{1,3}\.){3}[\d]{1,3})(:[0-9]{1,})?([\/#?]([^\s])*)?$/i;

    constructor(private decimalPipe: DecimalPipe) {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const domain: string = control.value;

        if (domain === undefined || domain === null || !domain.length) {
            return {[ClickTagDomainErrorCodes.Required]: this.getTranslationForErrorCode(ClickTagDomainErrorCodes.Required)};
        }

        if (domain.length > ClickTagDomainValidator.maxLength) {
            return {[ClickTagDomainErrorCodes.MaxLength]: this.getTranslationForErrorCode(ClickTagDomainErrorCodes.MaxLength)};
        }

        if (!ClickTagDomainValidator.pattern.test(domain)) {
            return {[ClickTagDomainErrorCodes.Pattern]: this.getTranslationForErrorCode(ClickTagDomainErrorCodes.Pattern)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: ClickTagDomainErrorCodes): string {
        switch (code) {
            case ClickTagDomainErrorCodes.Required:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case ClickTagDomainErrorCodes.MaxLength:
                const max: string | null = this.decimalPipe.transform(ClickTagDomainValidator.maxLength);

                return $localize`:@@VALIDATOR_CLICK_TAG_DOMAIN_MAX_LENGTH_ERROR_MESSAGE:VALIDATOR_CLICK_TAG_DOMAIN_MAX_LENGTH_ERROR_MESSAGE ${max}:INTERPOLATION:`;
            case ClickTagDomainErrorCodes.Pattern:
                return $localize`:@@VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum ClickTagDomainErrorCodes {
    Required = '8ce54b3c-5dc5-4745-bde8-d1fc53593cc5',
    MaxLength = '41ca9290-13f5-44e7-a354-8754bb30a443',
    Pattern = '02215bf8-f4af-4424-a63f-13bc9f77c584',
}

export {ClickTagDomainValidator, ClickTagDomainErrorCodes};
