import {CommonsError} from '@active-agent/types';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef,
} from '@angular/core';
import Fuse from 'fuse.js';
import FuseOptionKey = Fuse.FuseOptionKey;
import FuseResult = Fuse.FuseResult;
import {CommonModule} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CoreSearchToolbarModule} from '@angular-clan/core/search-toolbar';
import {MatDividerModule} from '@angular/material/divider';

@Component({
    selector: 'libs-multiselect',
    templateUrl: './multiselect.html',
    styleUrls: ['./multiselect.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        ScrollingModule,
        CoreSearchToolbarModule,
        MatDividerModule,
    ],
})
class LibsMultiselectComponent implements OnInit, OnChanges {
    @Input({required: true}) public optionValueTemplate!: TemplateRef<{option: MultiselectOption}>;
    @Input() public options: Array<MultiselectOption> = [];
    public readonly selectedOptions: Set<MultiselectOption> = new Set();
    @Input() public label: string = '';
    @Input() public isReadOnly: boolean = false;
    @Input() public searchFields: Array<FuseOptionKey> = ['name'];
    public filteredOptions: Array<MultiselectOption> = [];
    @Output() public onSelect: EventEmitter<Array<MultiselectOption>> = new EventEmitter<Array<MultiselectOption>>();
    @Output() public onPreSelect: EventEmitter<Array<MultiselectOption>> = new EventEmitter<Array<MultiselectOption>>();
    private shiftKeyPressed: boolean = false;
    private ctrlKeyPressed: boolean = false;
    private fuse: Fuse<MultiselectOption> = this.getFuseObject();
    private lastSelectedOption: MultiselectOption = null;
    private searchQuery: string = '';

    constructor(private changeDetector: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.optionValueTemplate === undefined) {
            throw new CommonsError('optionValueTemplate component binding has to be provided');
        }

        this.fuse = this.getFuseObject();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.options || changes.options.currentValue === undefined) {
            return;
        }
        this.fuse.setCollection(this.options);
        this.updateFilteredOptions();
        this.resetSelection();
    }

    public hasPermissions(): boolean {
        return !this.isReadOnly;
    }

    public onSearchChange(query: string): void {
        this.resetSelection();
        this.searchQuery = query;

        this.updateFilteredOptions();
    }

    public isOptionSelected(option: MultiselectOption): boolean {
        return this.selectedOptions.has(option);
    }

    public onClick(option: MultiselectOption): void {
        if (!this.hasPermissions()) {
            return;
        }

        // selects a range of items
        if (this.shiftKeyPressed && this.lastSelectedOption) {
            this.preSelectRangeOfOptions(option, this.lastSelectedOption);
        } else {
            this.preSelectSingleOption(option);
        }
        this.onPreSelect.emit(Array.from(this.selectedOptions.values()));
    }

    public onDoubleClick(option: MultiselectOption): void {
        if (!this.hasPermissions()) {
            return;
        }

        this.resetSelection();
        this.onSelect.emit([option]);
    }

    @HostListener('document:keydown', ['$event'])
    public onKeyDownHandler(event: KeyboardEvent): void {
        this.onKeyPress(event);
    }

    @HostListener('document:keyup', ['$event'])
    public onKeyUpHandler(event: KeyboardEvent): void {
        this.onKeyPress(event);
    }

    private updateFilteredOptions() {
        this.filteredOptions = this.searchQuery && this.searchQuery.length
            ? this.fuse.search(this.searchQuery).map((result: FuseResult<MultiselectOption>): MultiselectOption => result.item)
            : this.options;
        this.changeDetector.detectChanges();
    }

    private preSelectRangeOfOptions(option: MultiselectOption, lastSelectedOption: MultiselectOption): void {
        let startIndex: number = this.options.findIndex((item: MultiselectOption) => {
            return item === lastSelectedOption;
        });
        let endIndex: number = this.options.findIndex((item: MultiselectOption) => {
            return item === option;
        });

        if (startIndex > endIndex) {
            const startIndexTmp: number = startIndex;
            startIndex = endIndex;
            endIndex = startIndexTmp;
        }

        this.resetSelection();
        this.options.forEach((item: MultiselectOption, index: number) => {
            if (index >= startIndex && index <= endIndex) {
                this.toogleOption(item);
            }
        });
    }

    private preSelectSingleOption(option: MultiselectOption): void {
        if (this.isOptionSelected(option) && this.ctrlKeyPressed) {
            this.toogleOption(option);
        } else if (this.ctrlKeyPressed) {
            this.toogleOption(option);
        } else {
            this.resetSelection();
            this.toogleOption(option);
        }
        this.lastSelectedOption = option;
    }

    private onKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Shift') {
            this.shiftKeyPressed = event.type === 'keydown';
        }
        if (event.key === 'Control' || event.key === 'Meta') {
            this.ctrlKeyPressed = event.type === 'keydown';
        }

        this.changeDetector.detectChanges();
    }

    private toogleOption(option: MultiselectOption): void {
        if (this.selectedOptions.has(option)) {
            this.selectedOptions.delete(option);
        } else {
            this.selectedOptions.add(option);
        }
    }

    private resetSelection(): void {
        this.selectedOptions.clear();
        this.lastSelectedOption = null;
    }

    private getFuseObject(): Fuse<MultiselectOption> {
        return new Fuse([], {
            includeMatches: false,
            includeScore: false,
            isCaseSensitive: false,
            shouldSort: true,
            useExtendedSearch: true,
            findAllMatches: false,
            distance: 100,
            threshold: 0.3,
            keys: [
                ...this.searchFields,
            ],
        });
    }
}

type MultiselectOption = unknown;

export {LibsMultiselectComponent, MultiselectOption};
