import {CommonsError} from '@active-agent/types';
import {
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Cursor, Status} from '../status-icon/status-icon.component';

@Component({
    selector: 'libs-expansion-panel',
    templateUrl: './expansion-panel.html',
    styleUrls: ['./expansion-panel.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class LibsExpansionPanelComponent implements OnInit {
    @Input({required: true}) public title!: string;
    @Input() public subtitle?: string;
    @Input() public isActive: boolean = false;
    @Input() public expanded: boolean = false;
    @Input() public disabled: boolean = false;
    @Input() public showSwitch: boolean = false;
    @Input() public activateSwitch: boolean = false;
    @Input() public disableSwitch: boolean = false;
    @Input() public switchLabel: string | null = null;
    @Input() public switchTooltip: string | null = null;
    @Input() public highlightWhenOpened: boolean = false;
    @Input() public status: Status = Status.Success;
    public cursorPointer: Cursor = Cursor.Pointer;
    @Output() private expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() private onSwitchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private detectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        if (!this.title) {
            throw new CommonsError('title component binding has to be provided');
        }
    }

    public opened(): void {
        this.expandedChange.emit(true);
        this.detectorRef.detectChanges();
    }

    public closed(): void {
        this.expandedChange.emit(false);
        this.detectorRef.detectChanges();
    }

    public switchClicked(event: MouseEvent): void {
        event.stopPropagation();
    }

    public switchChanged(event: MatSlideToggleChange): void {
        this.onSwitchChange.emit(event.checked);
    }
}

export {LibsExpansionPanelComponent};
