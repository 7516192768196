import {Observable, of} from 'rxjs';
import {fromAditionAudioFilter, getAudioFilterOptions, IAudioTypeOption} from './audio-filter-parser-helper';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';

interface IAudioFilterProperties extends IFilterProperties {
    profile: string;
}

class AudioFilter extends Filter<FilterTypes.AUDIO> {
    protected readonly _type: FilterTypes.AUDIO = FilterTypes.AUDIO;
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = true;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IAudioFilterProperties = this.getDefaultOptions();

    public canBeInventoried(): Observable<boolean> {
        return of(this._isInventoriable);
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get profile(): string {
        return this.props.profile;
    }

    set profile(value: string) {
        this.props.profile = value;
    }

    public isValid(): Observable<boolean> {
        return of(isAudioProfileValid(this.profile));
    }

    protected getDefaultOptions(): IAudioFilterProperties {
        return {
            ...super.getDefaultOptions(),
            profile: '',
        };
    }
}

function isAudioProfileValid(profile: string = ''): boolean {
    try {
        const activeFilter: Array<string> = fromAditionAudioFilter(profile)
            .filter((item: IAudioTypeOption): boolean => item.active)
            .map((item: IAudioTypeOption): string => item.name);

        return !!getAudioFilterOptions()
            .filter((item: IAudioTypeOption): boolean => activeFilter.includes(item.name))
            .map((item: IAudioTypeOption): string => item.translation)
            .length;
    } catch (_error: unknown) {
        return false;
    }
}

export {AudioFilter, IAudioFilterProperties, isAudioProfileValid};
