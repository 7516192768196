<div class="pacing-bar">
    <div>
        <div class="vm-flex-direction-row vm-layout-justify-start vm-layout-align-end">
            <div class="pacing-bar__current-value">
                <core-count-up
                    *ngIf="!isLoading"
                    [value]="currentValue !== null ? currentValue : undefined"
                    [numberFormatCallback]="transformViewValueCallback"
                ></core-count-up>
                <div *ngIf="isLoading">
                    <mat-progress-spinner
                        mode="indeterminate"
                        color="primary"
                        class="pacing-bar__value-loading-indicator"
                        [diameter]="14"
                    ></mat-progress-spinner>
                </div>
            </div>

            <div class="vm-flex-grow"></div>
            <div class="pacing-bar__max-value">
                {{getTransformedViewValue(maxValue)}}
                <mat-icon
                    class="pacing-bar__max-value-icon"
                    [class.pacing-bar__max-value-icon--error]="isErrorTooltip()"
                    [class.pacing-bar__max-value-icon--raised]="isRaisedTooltip()"
                    [matTooltip]="getMaxValueTooltip()"
                    matTooltipClass="tooltip"
                    *ngIf="maxValueTooltip"
                >{{getMaxValueTooltipIcon()}}</mat-icon>
            </div>
        </div>
    </div>
    <div class="pacing-bar__progress-bar-container">
        <div *ngIf="!isLoading && !isProgressBarDisabled()">
            <mat-progress-bar
                mode="determinate"
                *ngIf="!isProgressBarDisabled()"
                [value]="getProgressValue()"
                class="pacing-bar__progress-bar"
            ></mat-progress-bar>
            <div
                class="pacing-bar__optimal-pace-bar"
                [style.left]="(optimalPace || 0) + '%'"
                *ngIf="isOptimalPaceAvailable()"
                [matTooltip]="getOptimalPaceTooltip()"
                matTooltipClass="tooltip"
            ></div>
        </div>
        <div class="pacing-bar__placeholder-bar" *ngIf="isProgressBarDisabled() || isLoading"></div>
    </div>
</div>
