import {ICloneable, IMergable} from '../../types';
import {FilterTypes} from './filter.types';

class CommonsSegment implements ICloneable<CommonsSegment>, IMergable<CommonsSegment> {
    private readonly _id: number | null;
    private _networkId: number;
    private _allowedFilterTypes: Array<FilterTypes> = [];
    private _category: CommonsSegmentCategory;
    private _cpm: number;
    private _cpmBilledByPartner: number | null = null;
    private _description: string | null = null;
    private _externalSegmentId: string;
    private _global: boolean;
    private _hasCpmOverride: boolean = false;
    private _name: string;
    private _type: CommonsSegmentType;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(
        id: number | null,
        networkId: number,
        category: CommonsSegmentCategory,
        cpm: number,
        externalSegmentId: string,
        global: boolean,
        name: string,
        type: CommonsSegmentType,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._category = category;
        this._cpm = cpm;
        this._externalSegmentId = externalSegmentId;
        this._global = global;
        this._name = name;
        this._type = type;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(value: number) {
        this._networkId = value;
    }

    get allowedFilterTypes(): Array<FilterTypes> {
        return this._allowedFilterTypes;
    }

    set allowedFilterTypes(value: Array<FilterTypes>) {
        this._allowedFilterTypes = value;
    }

    get category(): CommonsSegmentCategory {
        return this._category;
    }

    set category(value: CommonsSegmentCategory) {
        this._category = value;
    }

    get cpm(): number {
        return this._cpm;
    }

    set cpm(value: number) {
        this._cpm = value;
    }

    get cpmBilledByPartner(): number | null {
        return this._cpmBilledByPartner;
    }

    set cpmBilledByPartner(value: number | null) {
        this._cpmBilledByPartner = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get externalSegmentId(): string {
        return this._externalSegmentId;
    }

    set externalSegmentId(value: string) {
        this._externalSegmentId = value;
    }

    get global(): boolean {
        return this._global;
    }

    set global(value: boolean) {
        this._global = value;
    }

    get hasCpmOverride(): boolean {
        return this._hasCpmOverride;
    }

    set hasCpmOverride(value: boolean) {
        this._hasCpmOverride = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get type(): CommonsSegmentType {
        return this._type;
    }

    set type(value: CommonsSegmentType) {
        this._type = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public clone(): CommonsSegment {
        const newModel: CommonsSegment = new CommonsSegment(
            this.id,
            this.networkId,
            this.category,
            this.cpm,
            this.externalSegmentId,
            this.global,
            this.name,
            this.type,
        );
        newModel.merge(this);

        return newModel;
    }

    public merge(source: CommonsSegment): void {
        this.networkId = source.networkId;
        this.allowedFilterTypes = source.allowedFilterTypes;
        this.category = source.category;
        this.cpm = source.cpm;
        this.cpmBilledByPartner = source.cpmBilledByPartner;
        this.description = source.description;
        this.externalSegmentId = source.externalSegmentId;
        this.global = source.global;
        this.hasCpmOverride = source.hasCpmOverride;
        this.name = source.name;
        this.type = source.type;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }
}

enum CommonsSegmentType {
    IAS = 'IAS',
    AdSquare = 'ADSQUARE',
    Custom = 'CUSTOM',
    Odc = 'ODC',
    Capping = 'CAPPING',
    OttoPnta = 'OTTO_PNTA',
    WeischerAudiences = 'WEISCHER_AUDIENCES',
}

enum CommonsSegmentCategory {
    BrandSafety = 'BRAND_SAFETY',
    Viewability = 'VIEWABILITY',
    IabCategory = 'IAB_CATEGORY',
    Custom = 'CUSTOM',
    Contextual = 'CONTEXTUAL',
    InvalidTraffic = 'INVALID_TRAFFIC',
    Language = 'LANGUAGE',
    Predicts = 'PREDICTS',
    Footfall = 'FOOTFALL',
    AtvGraph = 'ATV_GRAPH',
    OttoPntaCustom = 'OTTO_PNTA_CUSTOM',
    OttoPntaGeo = 'OTTO_PNTA_GEO',
    OttoPntaIntent = 'OTTO_PNTA_INTENT',
    OttoPntaPurchase = 'OTTO_PNTA_PURCHASE',
    OttoPntaSozio = 'OTTO_PNTA_SOZIO',
    WeischerAudiencesTrace = 'WEISCHER_AUDIENCES_TRACE',
    WeischerAudiencesCustom = 'WEISCHER_AUDIENCES_CUSTOM',
    WeischerAudiencesDoohTvBoost = 'WEISCHER_AUDIENCES_DOOH_TV_BOOST',
    IasSiteContextControlAvoidance = 'IAS_SITE_CONTEXT_CONTROL_AVOIDANCE',
    IasSiteContextControlTargeting = 'IAS_SITE_CONTEXT_CONTROL_TARGETING',
    IasSiteInvalidTraffic = 'IAS_SITE_INVALID_TRAFFIC',
    IasAppInvalidTraffic = 'IAS_APP_INVALID_TRAFFIC',
}

export {CommonsSegment, CommonsSegmentType, CommonsSegmentCategory};
