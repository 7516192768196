import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {pluralize} from '@active-agent/pluralize';
import {IIcon} from '@active-agent/types';
import {CommonsBorderStyle} from '../../list-item/list-item.component';
import {CommonModule} from '@angular/common';
import {LibsCardWidgetComponent} from '../card-widget/card-widget.component';
import {CommonsListItemModule} from '../../list-item/list-item.module';
import {MatIconModule} from '@angular/material/icon';

@Component({
    selector: 'libs-list-widget',
    templateUrl: './list-widget.html',
    styleUrls: ['./list-widget.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        LibsCardWidgetComponent,
        CommonsListItemModule,
        MatIconModule,
    ],
})
class LibsListWidgetComponent {
    @Input({required: true}) public title!: string;
    @Input() public isLoading: boolean = false;
    @Input() public entries: Array<IListWidgetEntry> = [];
    public readonly borderStyle: CommonsBorderStyle = 'solid';
    private readonly defaultLimit: number = 2;

    public getLimit(): number {
        if (this.entries.length > 3) {
            return this.defaultLimit;
        }

        return 3;
    }

    public getFooterDescription(): string  | null {
        if (this.entries && this.entries.length <= this.getLimit()) {
            return null;
        }
        const count: number = this.entries.length - this.getLimit();

        return pluralize(
            {
                1: $localize`:@@MORE_ITEMS_AVAILABLE_SINGULAR:MORE_ITEMS_AVAILABLE_SINGULAR ${count}:INTERPOLATION:`,
                other: $localize`:@@MORE_ITEMS_AVAILABLE_PLURAL:MORE_ITEMS_AVAILABLE_PLURAL ${count}:INTERPOLATION:`,
            },
            this.entries.length - this.getLimit(),
        );
    }

    public hasFooterDescription(): boolean {
        return !!this.getFooterDescription();
    }
}

interface IListWidgetEntry {
    listIcon: IIcon;
    title: string;
    subtitle?: string;
    statusIcon: {
        iconColor: ThemePalette | null;
        icon: IIcon;
    };
}

export {LibsListWidgetComponent, IListWidgetEntry};
