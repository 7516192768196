import {ApplicationRef, Component, Inject, Optional} from '@angular/core';
import {CoreConfirmationDialogComponent, IDialogConfirmationData, ConfirmationStatus} from '@angular-clan/core/confirmation-dialog';
import {Status} from '../status-icon/status-icon.component';
import {CommonsEventLog, ICommonsEventLogger, EVENT_LOGGER} from '@active-agent/event';
import {
    SwUpdate,
    UnrecoverableStateEvent,
    VersionEvent,
    VersionReadyEvent,
} from '@angular/service-worker';
import {MatDialog} from '@angular/material/dialog';
import {concat, interval, Observable} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {ILibsEnvironment, LIBS_ENVIRONMENT} from '@active-agent/types';

@Component({
    selector: 'commons-new-version',
    templateUrl: './new-version-toolbar.html',
    styleUrls: ['./new-version-toolbar.scss'],
})
class CommonsNewVersionToolbarComponent {
    public infoStatus: Status = Status.Info;

    public isNewVersionAvailable: boolean = false;

    constructor(
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private dialog: MatDialog,
        @Optional() @Inject(EVENT_LOGGER) private eventLogger: ICommonsEventLogger | null,
        @Inject(LIBS_ENVIRONMENT) private environment: ILibsEnvironment,
    ) {
        this.registerCheckForUpdateTimer();
        this.registerNewVersionHandler();
        this.registerUnrecoverableStateHandler();
    }

    public refreshPage(): void {
        window.location.reload();
    }

    private registerCheckForUpdateTimer(): void {
        // https://angular.io/guide/service-worker-communications#checking-for-updates
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable: Observable<boolean> = this.appRef.isStable.pipe(first((isStable: boolean) => isStable));
        const refreshInterval: Observable<number> = interval(60 * 1000);
        const intervalOnceAppIsStable: Observable<unknown> = concat(appIsStable, refreshInterval);

        intervalOnceAppIsStable.subscribe((): void => {
            if (this.swUpdate.isEnabled) {
                void this.swUpdate.checkForUpdate();
            }
        });
    }

    private registerNewVersionHandler(): void {
        this.swUpdate.versionUpdates
            .pipe(
                filter<VersionEvent, VersionReadyEvent>((current: VersionEvent): current is VersionReadyEvent => {
                    return current.type === 'VERSION_READY';
                }),
            )
            .subscribe((event: VersionReadyEvent): void => {
                this.isNewVersionAvailable = true;
                const newVersion: string = (event.latestVersion.appData as Record<string, string>).version;
                const eventLog: CommonsEventLog = new CommonsEventLog(
                    'version.outdated',
                    {oldVersion: this.environment.version, newVersion},
                );
                if (this.eventLogger) {
                    void this.eventLogger.log(eventLog);
                }
            });
    }

    // https://angular.io/guide/service-worker-communications#handling-an-unrecoverable-state
    private registerUnrecoverableStateHandler(): void {
        this.swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
            const eventLog: CommonsEventLog = new CommonsEventLog(
                'version.unrecoverable',
                {version: this.environment.version, reason: event.reason, type: event.type},
            );
            if (this.eventLogger) {
                void this.eventLogger.log(eventLog);
            }

            this.dialog.open<CoreConfirmationDialogComponent, IDialogConfirmationData>(
                CoreConfirmationDialogComponent,
                {
                    data: {
                        title: $localize`:@@APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_TITLE:APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_TITLE`,
                        description: $localize`:@@APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_DESCRIPTION:APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_DESCRIPTION`,
                        status: ConfirmationStatus.Info,
                        hasCancelButton: false,
                        confirmButtonLabel: $localize`:@@APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_CONFIRM_BUTTON:APP_MODULE_SERVICE_WORKER_UNRECOVERABLE_RELOAD_DIALOG_CONFIRM_BUTTON`,
                    },
                },
            )
                .afterClosed()
                .subscribe(() => {
                    window.location.reload();
                    const dialogEventLog: CommonsEventLog = new CommonsEventLog(
                        'version.unrecoverable.reload',
                        {version: this.environment.version},
                    );
                    if (this.eventLogger) {
                        void this.eventLogger.log(dialogEventLog);
                    }
                });
        });
    }
}

export {CommonsNewVersionToolbarComponent};
