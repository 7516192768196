import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CoreCookieService} from '@angular-clan/core';
import {catchError, tap} from 'rxjs/operators';
import {createDspInterceptionRequest} from './create-dsp-interception-request';
import {IGenericObject, ILibsDspEnvironment, LogoutReason, UnauthorizedRequestError} from '@active-agent/types';
import {LibsEventLoggerService, LibsSlowLog} from '@active-agent/event';

@Injectable({
    providedIn: 'root',
})
abstract class DspApiRequestInterceptorService implements HttpInterceptor {
    public static userLoggedOutReason: LogoutReason | null = null;
    protected abstract urlsToIntercept: Array<string>;
    protected abstract environment: ILibsDspEnvironment;

    protected constructor(
        private cookieService: CoreCookieService,
        private eventLogger: LibsEventLoggerService,
        private http: HttpClient,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const interceptionAvailable: boolean = this.urlsToIntercept.some((url: string) => request.url.includes(url));
        if (!interceptionAvailable) {
            return next.handle(request);
        }

        const newRequest: HttpRequest<unknown> = createDspInterceptionRequest(request, this.cookieService);

        const startTime: Date = new Date();

        return next.handle(newRequest)
            .pipe(
                tap((response: HttpEvent<unknown>): HttpResponse<unknown> | HttpEvent<unknown> => {
                    if (response instanceof HttpResponse && response.url?.includes(this.environment.apiUrl)) {
                        this.logSlowRequest(newRequest, startTime);
                    }

                    return response;
                }),
                catchError((rejection: HttpErrorResponse): Observable<never> => {
                    this.checkForMaintenanceStatus();

                    return this.handleRequestError(rejection, newRequest, startTime);
                }),
            );
    }

    protected handleRequestError(rejection: HttpErrorResponse, newRequest: HttpRequest<unknown>, startTime: Date) {
        if (!rejection.url || !rejection.url.includes(this.environment.apiUrl)) {
            return throwError(() => rejection);
        }
        this.logSlowRequest(newRequest, startTime);

        if (rejection.status === HttpStatusCode.Unauthorized) {
            this.redirectToLogin(LogoutReason.Unauthorized);

            return throwError(() => new UnauthorizedRequestError('api request failed with unauthorized status', {cause: rejection}));
        }

        return throwError(() => rejection);
    }

    protected abstract redirectToLogin(logoutReason: LogoutReason): void;

    protected logSlowRequest(request: HttpRequest<unknown>, startTime: Date): void {
        if (!request || !request.url) {
            return;
        }
        const endTime: Date = new Date();
        const duration: number = (endTime.valueOf() - startTime.valueOf()) / 1000;

        const requestParams: IGenericObject = {};
        request.params.keys().forEach((param: string) => {
            requestParams[param] = request.params.get(param);
        });

        const slowLog: LibsSlowLog = new LibsSlowLog(
            duration,
            request.url,
            request.method,
            requestParams,
            this.environment,
        );

        this.eventLogger.logSlowLog(slowLog);
    }

    /**
     * This triggers the maintenance page if the request fails.
     * The maintenance page will return an error code and with a refresh you are guaranteed to see the maintenance page
     */
    private checkForMaintenanceStatus() {
        this.http.head('/')
            .subscribe({
                error: (response: HttpResponse<unknown>) => {
                    if (response.status.valueOf() === HttpStatusCode.ServiceUnavailable) {
                        window.location.href = '/';
                    }
                },
            });
    }
}

export {DspApiRequestInterceptorService};
