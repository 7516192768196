import {CommonsError, IGenericObject} from '@active-agent/types';
import {CommonsRequestBuilder} from './request-builder';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpEvent} from '@angular/common/http';

class CommonsPostMultiPartRequestBuilder extends CommonsRequestBuilder {
    private readonly data: FormData;

    constructor(
        private http: HttpClient,
        resourcePath: string,
        basePath: string,
        data: FormData,
    ) {
        super(resourcePath, basePath);
        this.data = data;
    }

    /**
     * Creates a valid api creation request and fetches the result from the api
     */
    public build(): Observable<HttpEvent<IGenericObject>> {
        return this.post();
    }

    /**
     * Returns an observable of the creation of the given object on the given resource.
     *
     * @throws {CommonsError}
     */
    public post(): Observable<HttpEvent<IGenericObject>> {
        let url: string = this.getApiBaseUrl();

        if (this.getSubResourcePath()) {
            const queryIds: Array<number | string> | null = this.getQueryIds();
            if (!queryIds || !queryIds.length) {
                return throwError(() => new CommonsError(
                    'param "parentId" must be given',
                    {
                        data: {
                            resourcePath: this.getResourcePath(),
                            subResourcePath: this.getSubResourcePath(),
                            object: this.data,
                        },
                    },
                ));
            }
            url = `/${queryIds.join(',')}/${this.getSubResourcePath()}`;
        }

        Object.keys(this.data).forEach((key: string): void => {
            const value: unknown = this.data[key as keyof FormData];
            if (Object.is(value, null)) {
                delete this.data[key as keyof FormData];
            }
        });

        return this.http
            .post(url, this.data, {
                headers: this.getHeadersForRequest(),
                params: this.getQueryParamsForRequest(),
                reportProgress: true,
                observe: 'events',
            });
    }
}

export {CommonsPostMultiPartRequestBuilder};
