import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class AgencyIdValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<number | null>): ValidationErrors | null {
        const agencyId: number | null = control.value;

        if (agencyId === null) {
            return {[AgencyIdErrorCodes.Required]: this.getTranslationForErrorCode(AgencyIdErrorCodes.Required)};
        }

        if (!Number.isFinite(agencyId)) {
            return {[AgencyIdErrorCodes.NaN]: this.getTranslationForErrorCode(AgencyIdErrorCodes.NaN)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: AgencyIdErrorCodes): string {
        switch (code) {
            case AgencyIdErrorCodes.Required:
                return $localize`:@@AGENCY_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:AGENCY_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            case AgencyIdErrorCodes.NaN:
                return $localize`:@@AGENCY_ID_VALIDATOR_FIELD_NOT_A_NUMBER_ERROR_MESSAGE:AGENCY_ID_VALIDATOR_FIELD_NOT_A_NUMBER_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum AgencyIdErrorCodes {
    Required = '7148a8bc-ff75-4b49-b8d7-ec4b0ac86e24',
    NaN = 'cc6d7cd1-a90c-43a6-970a-fc2f182e4226',
}

export {AgencyIdValidator, AgencyIdErrorCodes};
