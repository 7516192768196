import {Injectable} from '@angular/core';
import {defer, Observable, of} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
class LibsAdBlockDetectorService {
    private static readonly FAKE_AD_URL: string = 'assets/commons/ad-block-detector/prebid-ads.js';

    constructor(private http: HttpClient) {}

    public isAddBlockerDetected(): Observable<boolean> {
        return defer(() => this.detectAnyAdBlock());
    }

    private detectAnyAdBlock(): Observable<boolean> {
        if (detectDomAdBlocker()) {
            return of(true);
        }

        return this.detectRequestAdBlocker();
    }

    /**
     * Requests a test file named `prebid-ads.js` to check if it gets blocked by ad blockers.
     * This file name is listed in the common ad blockers' block lists.
     * Returns `true` if the request is blocked, `false` if not.
     *
     * Tested with:
     *   - AdBlock
     *   - AdBlock Plus
     *   - AdBlocker Ultimate
     *   - AdGuard Werbeblocker
     *   - Ghostery
     *   - uBlock Origin
     */
    private detectRequestAdBlocker(): Observable<boolean> {
        return this.http.get(LibsAdBlockDetectorService.FAKE_AD_URL)
            .pipe(
                map(() => false),
                catchError((error: unknown) => of(
                    /**
                     * Catch all client errors (all 400s).
                     */
                    error instanceof HttpErrorResponse && (
                        error.status === 0 || // We check also for `0` because some ad-blockers don't set proper status codes.
                        error.status >= HttpStatusCode.BadRequest && error.status < HttpStatusCode.InternalServerError
                    ),
                )),
            );
    }
}

function createBaitElement(): HTMLElement {
    const bait: HTMLDivElement = document.createElement('div');

    bait.setAttribute('class',
        // eslint-disable-next-line @stylistic/max-len
        'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner');
    bait.setAttribute('style',
        // eslint-disable-next-line @stylistic/max-len
        'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;');

    return bait;
}

function isElementBlocked(elem: HTMLElement): boolean {
    const elemCS: CSSStyleDeclaration = getComputedStyle(elem, null);

    return elemCS && (elemCS.getPropertyValue('display') === 'none' || elemCS.getPropertyValue('visibility') === 'hidden');
}

function detectDomAdBlocker(): boolean {
    if (isAdBlockerPremiumEnabled()) {
        return true;
    }

    const bait: HTMLElement = createBaitElement();
    window.document.body.appendChild(bait);

    return isElementBlocked(bait);
}

function isAdBlockerPremiumEnabled(): boolean {
    return window.document.body.getAttribute('abp') !== null;
}

export {LibsAdBlockDetectorService};
