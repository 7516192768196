import {cloneDeep} from 'lodash-es';
import {CampaignRichMediaBanner} from './rich-media/campaign-rich-media-banner';
import {CampaignGenericBanner} from './generic/campaign-generic-banner';
import {CampaignHtml5Banner} from './html5/campaign-html5-banner';
import {CampaignImageBanner} from './image/campaign-image-banner';
import {CampaignNativeBanner} from './native/campaign-native-banner';
import {CampaignSwitchInBanner} from './switch-in/campaign-switch-in-banner';
import {CampaignSwitchInXxlBanner} from './switch-in-xxl/campaign-switch-in-xxl-banner';
import {CampaignVastBanner} from './vast/campaign-vast-banner';
import {CampaignRedirectBanner} from './redirect/campaign-redirect-banner';
import {CampaignVastWrapperBanner} from './vast-wrapper/campaign-vast-wrapper-banner';
import {BannerPoolGenericBanner} from './generic/banner-pool-generic-banner';
import {BannerPoolHtml5Banner} from './html5/banner-pool-html5-banner';
import {BannerPoolImageBanner} from './image/banner-pool-image-banner';
import {BannerPoolNativeBanner} from './native/banner-pool-native-banner';
import {BannerPoolRedirectBanner} from './redirect/banner-pool-redirect-banner';
import {BannerPoolRichMediaBanner} from './rich-media/banner-pool-rich-media-banner';
import {BannerPoolSwitchInBanner} from './switch-in/banner-pool-switch-in-banner';
import {BannerPoolSwitchInXxlBanner} from './switch-in-xxl/banner-pool-switch-in-xxl-banner';
import {BannerPoolVastBanner} from './vast/banner-pool-vast-banner';
import {BannerPoolVastWrapperBanner} from './vast-wrapper/banner-pool-vast-wrapper-banner';
import {GenericBanner} from './generic/generic-banner';
import {Html5Banner} from './html5/html5-banner';
import {ImageBanner} from './image/image-banner';
import {NativeBanner} from './native/native-banner';
import {RedirectBanner} from './redirect/redirect-banner';
import {RichMediaBanner} from './rich-media/rich-media-banner';
import {SwitchInBanner} from './switch-in/switch-in-banner';
import {SwitchInXxlBanner} from './switch-in-xxl/switch-in-xxl-banner';
import {VastBanner} from './vast/vast-banner';
import {VastWrapperBanner} from './vast-wrapper/vast-wrapper-banner';
import {AudioBanner} from './audio/audio-banner';
import {BannerPoolAudioBanner} from './audio/banner-pool-audio-banner';
import {CampaignAudioBanner} from './audio/campaign-audio-banner';
import {BannerPoolVideoBanner} from './video/banner-pool-video-banner';
import {CampaignVideoBanner} from './video/campaign-video-banner';
import {CampaignDoohBanner} from './dooh/campaign-dooh-banner';
import {BannerPoolDoohBanner} from './dooh/banner-pool-dooh-banner';
import {DoohBanner} from './dooh/dooh-banner';
import {VideoBanner} from './video/video-banner';
import {BannerSspStatus} from './banner-ssp-status';
import {SwitchInXxlAnimatedBanner} from './switch-in-xxl-animated/switch-in-xxl-animated-banner';
import {CampaignSwitchInXxlAnimatedBanner} from './switch-in-xxl-animated/campaign-switch-in-xxl-animated-banner';
import {BannerPoolSwitchInXxlAnimatedBanner} from './switch-in-xxl-animated/banner-pool-switch-in-xxl-animated-banner';
import {BumperAdBanner} from './bumper-ad/bumper-ad-banner';
import {CampaignBumperAdBanner} from './bumper-ad/campaign-bumper-ad-banner';
import {BannerPoolBumperAdBanner} from './bumper-ad/banner-pool-bumper-ad-banner';
import {DeprecatedAtvSpotBanner} from './deprecated-atv-spot/deprecated-atv-spot-banner';
import {DeprecatedBannerPoolAtvSpotBanner} from './deprecated-atv-spot/deprecated-banner-pool-atv-spot-banner';
import {DeprecatedCampaignAtvSpotBanner} from './deprecated-atv-spot/deprecated-campaign-atv-spot-banner';
import {AtvSpotBanner} from './atv-spot/atv-spot-banner';
import {CampaignAtvSpotBanner} from './atv-spot/campaign-atv-spot-banner';
import {BannerPoolAtvSpotBanner} from './atv-spot/banner-pool-atv-spot-banner';
import {PtvAdSpotBanner} from './ptv-ad-spot/ptv-ad-spot-banner';
import {CampaignPtvAdSpotBanner} from './ptv-ad-spot/campaign-ptv-ad-spot-banner';
import {BannerPoolPtvAdSpotBanner} from './ptv-ad-spot/banner-pool-ptv-ad-spot-banner';
import {ICloneable, IMergable} from '../types';
import {IBanner, IStatusMessages, ITcfVendor} from './banner';
import {BannerTypes} from './banner-types';
import {IRuntime} from '../runtime/runtime';
import {CommonsError} from '../error/error';
import {ThirdPartyFeatureType} from '../third-party-feature';

class BaseBanner implements IBanner, IMergable<BaseBanner>, ICloneable<BaseBanner> {
    private readonly _id: number | null;
    private readonly _networkId: number;
    private _name: string;
    private _active: boolean = false;
    private _landingPage: string;
    private _runtimes: Array<IRuntime> = [];
    private _infiniteRuntime: boolean = false;
    private _remainingRuntimeDays: number | undefined;
    private _runtimePercentage: number | undefined;
    private _filterIds: Array<string> = [];
    private _lastUpdate: number | undefined;
    private _status: IBannerStatus = {};
    private _statusMessages: IStatusMessages = {};
    private _valid: boolean = false;
    private _errorMessage: string | undefined;
    private _deleted: boolean = false;
    private _footfallScreenConfigId: number | undefined;
    private _tcfCompliant: boolean | null = null;
    private _tcfVendors: Array<ITcfVendor> = [];
    private _activeThirdPartyFeatures: Array<ThirdPartyFeatureType> = [];

    constructor(
        id: number | null,
        networkId: number,
        name: string,
        landingPage: string,
    ) {
        this._id = id;
        this._networkId = networkId;
        this._name = name;
        this._landingPage = landingPage;
    }

    get id(): number | null {
        return this._id;
    }

    get networkId(): number {
        return this._networkId;
    }

    get type(): BannerTypes {
        throw new CommonsError('Type property is not available on base banner');
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get landingPage(): string {
        return this._landingPage;
    }

    set landingPage(value: string) {
        this._landingPage = value;
    }

    get runtimes(): Array<IRuntime> {
        return this._runtimes;
    }

    set runtimes(value: Array<IRuntime>) {
        this._runtimes = value;
    }

    get infiniteRuntime(): boolean {
        return this._infiniteRuntime;
    }

    set infiniteRuntime(value: boolean) {
        this._infiniteRuntime = value;
    }

    get remainingRuntimeDays(): number | undefined {
        return this._remainingRuntimeDays;
    }

    set remainingRuntimeDays(value: number | undefined) {
        this._remainingRuntimeDays = value;
    }

    get runtimePercentage(): number | undefined {
        return this._runtimePercentage;
    }

    set runtimePercentage(value: number | undefined) {
        this._runtimePercentage = value;
    }

    get filterIds(): Array<string> {
        return this._filterIds;
    }

    set filterIds(value: Array<string>) {
        this._filterIds = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get status(): IBannerStatus {
        return this._status;
    }

    set status(value: IBannerStatus) {
        this._status = value;
    }

    get statusMessages(): IStatusMessages {
        return this._statusMessages;
    }

    set statusMessages(value: IStatusMessages) {
        this._statusMessages = value;
    }

    get valid(): boolean {
        return this._valid;
    }

    set valid(value: boolean) {
        this._valid = value;
    }

    get errorMessage(): string | undefined {
        return this._errorMessage;
    }

    set errorMessage(value: string | undefined) {
        this._errorMessage = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get tcfCompliant(): boolean | null {
        return this._tcfCompliant;
    }

    set tcfCompliant(value: boolean | null) {
        this._tcfCompliant = value;
    }

    get tcfVendors(): Array<ITcfVendor> {
        return this._tcfVendors;
    }

    set tcfVendors(value: Array<ITcfVendor>) {
        this._tcfVendors = value;
    }

    get activeThirdPartyFeatures(): Array<ThirdPartyFeatureType> {
        return this._activeThirdPartyFeatures;
    }

    set activeThirdPartyFeatures(thirdPartyFeatures: Array<ThirdPartyFeatureType>) {
        this._activeThirdPartyFeatures = thirdPartyFeatures;
    }

    public get footfallScreenConfigId(): number | undefined {
        return this._footfallScreenConfigId;
    }

    public set footfallScreenConfigId(value: number | undefined) {
        this._footfallScreenConfigId = value;
    }

    public merge(source: BaseBanner): void {
        BaseBanner.fillAdditionalFields(this, source);
    }

    public clone(): BaseBanner {
        const newModel: BaseBanner = new BaseBanner(
            this.id,
            this.networkId,
            this.name,
            this.landingPage,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: BaseBanner, source: BaseBanner): void {
        target.name = source.name;
        target.active = source.active;
        target.landingPage = source.landingPage;
        target.runtimes = cloneDeep (source.runtimes);
        target.infiniteRuntime = source.infiniteRuntime;
        target.filterIds = source.filterIds;
        target.lastUpdate = source.lastUpdate;
        target.status = cloneDeep(source.status);
        target.statusMessages = cloneDeep(source.statusMessages);
        target.valid = source.valid;
        target.errorMessage = source.errorMessage;
        target.deleted = source.deleted;
        target.footfallScreenConfigId = source.footfallScreenConfigId;
        target.tcfCompliant = source.tcfCompliant;
        target.activeThirdPartyFeatures = source.activeThirdPartyFeatures.map((feature: ThirdPartyFeatureType) => feature);
        target.tcfVendors = source.tcfVendors.map((vendor: ITcfVendor) => vendor);
    }
}

type CampaignBanner = CampaignGenericBanner
| CampaignHtml5Banner
| CampaignImageBanner
| CampaignNativeBanner
| CampaignRedirectBanner
| CampaignRichMediaBanner
| CampaignSwitchInBanner
| CampaignSwitchInXxlBanner
| CampaignSwitchInXxlAnimatedBanner
| CampaignVastBanner
| CampaignVastWrapperBanner
| CampaignAudioBanner
| CampaignVideoBanner
| CampaignDoohBanner
| DeprecatedCampaignAtvSpotBanner
| CampaignAtvSpotBanner
| CampaignBumperAdBanner
| CampaignPtvAdSpotBanner;

type BannerPoolBanner = BannerPoolGenericBanner
| BannerPoolHtml5Banner
| BannerPoolImageBanner
| BannerPoolNativeBanner
| BannerPoolRedirectBanner
| BannerPoolRichMediaBanner
| BannerPoolSwitchInBanner
| BannerPoolSwitchInXxlBanner
| BannerPoolSwitchInXxlAnimatedBanner
| BannerPoolVastBanner
| BannerPoolVastWrapperBanner
| BannerPoolAudioBanner
| BannerPoolVideoBanner
| BannerPoolDoohBanner
| DeprecatedBannerPoolAtvSpotBanner
| BannerPoolAtvSpotBanner
| BannerPoolBumperAdBanner
| BannerPoolPtvAdSpotBanner;

type Banner = GenericBanner
| Html5Banner
| ImageBanner
| NativeBanner
| RedirectBanner
| RichMediaBanner
| SwitchInBanner
| SwitchInXxlBanner
| SwitchInXxlAnimatedBanner
| VastBanner
| VastWrapperBanner
| AudioBanner
| VideoBanner
| DoohBanner
| DeprecatedAtvSpotBanner
| AtvSpotBanner
| BumperAdBanner
| PtvAdSpotBanner;

interface IBannerStatus {
    [key: string]: BannerSspStatus;
}

export {BaseBanner, CampaignBanner, BannerPoolBanner, Banner, IBannerStatus};
