import {
    IRouterHandlerData,
    IOrderRoutesConfiguration,
    IOrderSettingsRouteData,
    IOrderCampaignsListRouteData,
    IOrderCampaignRouteData,
} from '@active-agent/types';
import {AppPath} from '../../../../../app/commons/enums';

const orderRoutesConfiguration: IOrderRoutesConfiguration = {
    settings: {
        getDataForLink: (data: IOrderSettingsRouteData, networkId: number): IRouterHandlerData => {
            return {
                commands: [
                    networkId,
                    AppPath.Order,
                    data.orderId,
                    AppPath.Settings,
                ],
            };
        },
    },
    campaigns: {
        getDataForLink: (data: IOrderCampaignsListRouteData, networkId: number): IRouterHandlerData => {
            return {
                commands: [
                    networkId,
                    AppPath.Order,
                    data.orderId,
                    AppPath.Campaigns,
                    AppPath.List,
                ],
            };
        },
    },
    campaignIds: {
        getDataForLink: (data: IOrderCampaignRouteData, networkId: number): IRouterHandlerData => {
            return {
                commands: [
                    networkId,
                    AppPath.Campaign,
                    data.campaignId,
                    AppPath.Settings,
                ],
            };
        },
    },
};

export {orderRoutesConfiguration};
