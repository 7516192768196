import {InjectionToken} from '@angular/core';

interface ILibsEnvironment {
    version: string;
    debug: boolean;
    sentryUrl: string | null;
}

const LIBS_ENVIRONMENT: InjectionToken<string> = new InjectionToken<string>('environment.global');

export {ILibsEnvironment, LIBS_ENVIRONMENT};
