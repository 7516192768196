import {InjectionToken} from '@angular/core';
import {IRouterHandlerRouteConfiguration} from '../route';

const ORDER_ROUTES_CONFIGURATION: InjectionToken<IOrderRoutesConfiguration> =
    new InjectionToken('order-routes-configuration');

interface IOrderRoutesConfiguration {
    settings: IRouterHandlerRouteConfiguration<IOrderSettingsRouteData>;
    campaigns: IRouterHandlerRouteConfiguration<IOrderCampaignsListRouteData>;
    campaignIds: IRouterHandlerRouteConfiguration<IOrderCampaignRouteData>;
}

interface  IOrderSettingsRouteData {
    orderId: number;
}

interface  IOrderCampaignsListRouteData {
    orderId: number;
}

interface  IOrderCampaignRouteData {
    campaignId: number;
}

export {
    ORDER_ROUTES_CONFIGURATION,
    IOrderSettingsRouteData,
    IOrderCampaignsListRouteData,
    IOrderRoutesConfiguration,
    IOrderCampaignRouteData,
};
