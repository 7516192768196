import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
class AgencyIdsValidator implements Validator {
    constructor() {
        this.validate = this.validate.bind(this);
    }

    public validate(control: AbstractControl<Array<number>>): ValidationErrors | null {
        const agencyIds: Array<number> = control.value;

        if (!Array.isArray(agencyIds) || !agencyIds.length) {
            return {[AgencyIdsErrorCodes.Required]: this.getTranslationForErrorCode(AgencyIdsErrorCodes.Required)};
        }

        return null;
    }

    private getTranslationForErrorCode(code: AgencyIdsErrorCodes): string {
        switch (code) {
            case AgencyIdsErrorCodes.Required:
                return $localize`:@@AGENCY_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE:AGENCY_VALIDATOR_FIELD_REQUIRED_ERROR_MESSAGE`;
            default:
                return $localize`:@@VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE:VALIDATOR_UNKNOWN_CODE_ERROR_MESSAGE`;
        }
    }
}

enum AgencyIdsErrorCodes {
    Required = 'dda28c89-6f3a-4d6c-b104-889ba3aa9d0a',
}

export {AgencyIdsValidator, AgencyIdsErrorCodes};
