import {NotificationService} from '@active-agent/notification';
import {getTimeZonedNewDate} from '@active-agent/std';
import {DateFormat, CommonsError, UnauthorizedRequestError, ErrorLog, ILibsEnvironment} from '@active-agent/types';
import {format} from 'date-fns';
import {LibsErrorHandler} from './error-handler';
import {Router} from '@angular/router';
import {ICommonsErrorHandler} from './error-handler.types';
import {findCritical} from './find-critical';
import {CoreCookieService} from '@angular-clan/core';

class LibsErrorHandlerService implements ICommonsErrorHandler {

    private readonly defaultToastMessage: string = $localize`:@@ERROR_FAILURE_MESSAGE:ERROR_FAILURE_MESSAGE`;

    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private environment: ILibsEnvironment,
        private cookieService: CoreCookieService,
    ) { }

    public sendToErrorLoggerService(error: IErrorObject): void {
        const errorLog: ErrorLog = new ErrorLog(
            error.message || '',
            error.data,
            error.stackTrace || '',
            error.dateTime || '',
            error.state || {},
            error.isCritical,
            error.error,
        );

        new LibsErrorHandler(this.environment).handleError(errorLog);
    }

    /**
     * Logs an error.
     * If environment.debug is set it will be logged to the console
     * If environment.sentryUrl is present it will also be logged to Sentry
     */
    public async handle(
        error: CommonsError | Error,
        showToast: boolean = true,
        toastMessage: string = this.defaultToastMessage,
    ): Promise<void> {
        if (this.environment.debug && !(error instanceof CommonsError || error instanceof UnauthorizedRequestError)) {
            console.warn(
                'LibsErrorHandlerService',
                'Unknown Error caught. Please replace this error with a Commons Error',
                error,
            );
        }
        if (
            error instanceof UnauthorizedRequestError
            || (!this.cookieService.get('token') && this.router.url.includes('/login'))
        ) {
            return;
        }

        this.storeError(error);

        if (showToast) {
            this.notificationService.showError(toastMessage);
        }
    }

    private storeError(error: Error | CommonsError): void {
        const errorObject: IErrorObject = {
            dateTime: format(getTimeZonedNewDate(), DateFormat.ApiRequestDateTime),
            message: error.message,
            state: {
                url: JSON.stringify(this.router.url),
            },
            stackTrace: 'stack' in error ? error.stack : '',
        };
        if (error instanceof CommonsError) {
            errorObject.data = error.getData();
            errorObject.isCritical = findCritical(error);
            errorObject.error = error;
        } else {
            errorObject.message = 'generic error caught';
            errorObject.data = error;
            errorObject.isCritical = false;
        }
        this.sendToErrorLoggerService(errorObject);
    }
}

interface IErrorObject {
    dateTime?: string;
    message?: string;
    state?: Record<string, unknown>;
    stackTrace?: string;
    isCritical?: boolean;
    error?: Error;
    data?: unknown;
}

export {LibsErrorHandlerService};
