<div class="dynamic-progress-bar">
    <div class="dynamic-progress-bar__progress-bar">
        <div
            class="dynamic-progress-bar__progress-bar-stripes"
            [class.dynamic-progress-bar__progress-bar-stripes--disabled]="disableAnimation()"
            [style.width.%]="progressPercentage()"
        ></div>
    </div>
    <div class="dynamic-progress-bar__progress-text" #progressText>
        @if (progressPercentage() === 100) {
            <ng-content select="[libs-dynamic-progress-bar-progress-finished-text]"></ng-content>
        } @else {
            <ng-content select="[libs-dynamic-progress-bar-progress-loading-text]"></ng-content>
        }
    </div>
</div>
